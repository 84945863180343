import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import defaultImg from '../../assets/default-image.jpg';
import { fixedZero } from '../../utils/utils';

export const orderStatusMapArr = [
  [1, 'Pending'],
  [2, 'Awaiting order'],
  [3, 'Awaiting payment'],
  [4, 'Awaiting shipment'],
  [9, 'Awaiting fulfillment'],
  [5, 'Fulfilled'],
  [6, 'Canceled'],
  [7, 'Failed orders']
];
export const orderStatusMap = {
  '1': 'Pending',
  '2': 'Awaiting order',
  '3': 'Awaiting payment',
  '4': 'Awaiting shipment',
  '5': 'Fulfilled',
  '6': 'Canceled',
  '7': 'Failed orders'
};

export const statusMap = {
  '1': 1,
  '2': 2,
  '3': 4,
  '4': 8,
  '5': 2048,
  '6': 16,
  '7': 32
};

const statusMapKey = {
  '1':1,
  '2':2,
  '4':3,
  '8':4,
  '2048':5,
  '16':6,
  '32':7
};

export function getCanceled(child, tabStatus) {
  let flag = false;
  if (child.canceledBy === 'shopify' && tabStatus == 6) {
    flag = true;
  }
  return flag;
}

export function getHasShopifyFulfilled(child, listData) {
  const {
    detail: { fulfillments }
  } = listData;
  let flag = true;

  if (fulfillments === undefined) {
    return false;
  }

  fulfillments.forEach(item => {
    if (item.status !== 'success') {
      return;
    }
    item.line_items.forEach(line => {
      if (line.id === child.id) {
        flag = true;
      }
    });
  });

  return flag;
}

export function getHasTrackingFulfilled(child, listData) {
  const {
    detail: { fulfillments }
  } = listData;
  let flag = true;
  let spHasTracking = false;

  if (fulfillments === undefined) {
    flag = false;
  } else {
    fulfillments.forEach(item => {
      if (item.status !== 'success') {
        return;
      }
      item.line_items.forEach(line => {
        if (line.id === child.id) {
          flag = true;
        }
      });
    });
  }

  child.sp.forEach(sp => {
    if (sp.status === 5 && sp.logistics_infos) {
      spHasTracking = true;
    }
  });

  return flag && spHasTracking;
}

export function getHasManuallyFulfilled(child, listData) {
  const {
    ext: { fulfillments }
  } = listData;

  if (fulfillments === undefined) {
    return false;
  }

  return fulfillments.includes(child.id);
}

export function getHasSourceOther(child) {
  let flag = true;

  child.sp.forEach(sp => {
    if (sp.platform_product_id) {
      flag = false;
    }
  });
  return flag;
}

export function getHasSourceAE(child) {
  let flag = true;

  child.sp.forEach(sp => {
    if (!sp.platform_product_id) {
      flag = false;
    }
  });
  return flag;
}

function getShopifyStoreDomain(oneStore = {}) {
  const { domain = '', shop_name } = oneStore;
  return domain?.includes('myshopify.com')
    ? `https://${domain}`
    : `https://${shop_name}.myshopify.com`;
}

// 获取订单在shopify上的详情地址
function getShopifyUrl(userInfo, detail, storeId) {
  const domain = getShopifyStoreDomain(
    userInfo?.stores?.find(store => store.id == storeId)
  );
  return `${domain}/admin/orders/${detail.id}`;
}

export const notEditOrderStatus = [0, 1]; // 不能修改订单
export const notEditOrderNoteStatus = [0]; // 不能修改note的状态
export const replaceExtStatus = [0, 1, 2, 6, 7];

const emptyAddress = {
  address: '',
  address2: '',
  city: '',
  company: '',
  contact_person: '',
  country: '',
  country_code: '',
  cpf: '',
  email: '',
  full_name: '',
  locale: '',
  mobile_no: '',
  phone_country: '',
  phone_number: '',
  province: '',
  zip: ''
};

const maptype = {
  MapBas: 1,
  MapAdv: 2,
  MapBog: 3,
  MapBun: 4,
  // standard mapping
  MapSta: 5,
  MapAgency: 6
};

/**
 * 获取使用ae或dsers商品 各包含多少卖家
 * @param {*} lineItem ext里的一条子订单
 * @param {*} Ext // 订单包含的商品对应的aliexpress商品数据 mappping数据
 * @param {*} address
 * @param {*} shopfiyLineItem // detail.line_items里的一条子订单(和ext.lineitems里的一条子订单对应)
 * @param {*} DsersExt // dsers自营商品数据 和 Ext 格式一样
 */
function getExtListSaleIds(lineItem, Ext, address, shopfiyLineItem, DsersExt) {
  const arr = [];

  if (!lineItem) {
    return arr;
  }

  const { country_code = '' } = address;

  const saleIdArr = [];

  let qty = 0;

  if (shopfiyLineItem.fulfillment_status == 'fulfilled') {
    // 完成单
    qty = 0;
  } else {
    qty = shopfiyLineItem.fulfillable_quantity || 0;
  }

  Ext.forEach(item => {
    // 对应关系 variant_id、country code、可下单数量、shopify商品id 相等
    if (
      item.var_id == lineItem.variant_id &&
      item.country == country_code &&
      qty == item.fulfillable_quantity &&
      item.prod_id == lineItem.product_id
    ) {
      if (!item.ali_id) {
        item.is_default = true;
        item.map_type = item.map_type == 'MapDef' ? 'MapBas' : item.map_type;
      }
      arr.push(item);
      !saleIdArr.includes(item.sale_id) && saleIdArr.push(item.sale_id); // 存ae卖家id
    }
  });

  // dsers 供应商
  const ExtIsDsers = DsersExt.Ext || [];
  const ExtMap = DsersExt.Map || [];
  const dsersArr = [];

  ExtIsDsers.forEach(item => {
    // 对应关系 variant_id、country code、可下单数量、shopify商品id 相等
    if (
      item.var_id == lineItem.variant_id &&
      item.country == country_code &&
      qty == item.fulfillable_quantity &&
      item.prod_id == lineItem.product_id
    ) {
      if (!item.ali_id) {
        item.is_default = true;
        item.map_type = item.map_type == 'MapDef' ? 'MapBas' : item.map_type;
      }
      item.is_ver = true;
      dsersArr.push(item); // 存对应商品信息
    }
  });

  // 替换成dsers卖家
  if (dsersArr.length) {
    dsersArr.forEach(item => {
      arr.forEach((ext, i) => {
        const key =
          ExtMap[`${ext.prod_id}:${ext.ali_id}:${ext.sku}:${ext.var_id}`];
        if (
          key &&
          key.Sku == item.sku &&
          key.SupplierProductId == item.ali_id &&
          canOrderPlace(lineItem)
        ) {
          if (item.map_type == 'MapBas') {
            if (ext.is_default == item.is_default) {
              arr.splice(i, 1, item);
            }
          } else {
            arr.splice(i, 1, item);
          }
        }
      });
    });
  }

  const newSaleIdArr = []; // 替换成dsers卖家后 卖家id数组
  arr.forEach(item => {
    !newSaleIdArr.includes(item.sale_id) && newSaleIdArr.push(item.sale_id);
  });

  return [saleIdArr, newSaleIdArr];
}

/**
 * 获取子订单包含的商品对应的aliexpress商品数据(这个shopify商品对应ae的那个商品,就是商品mapping数据)
 * @param {*} lineItem ext.lineitems里的一条数据
 * @param {*} Ext // 这页订单中 所有订单包含的商品 对应的ae商品数据
 * @param {*} address // 收货人信息
 * @param {*} shopfiyLineItem // detail.line_items里的一条数据
 * @param {*} DsersExt // dsers供应商数据
 * @param {*} canReplace // 能不能替换成dsers供应商
 * @param {*} types // 用户等级
 */
function getExtList(
  lineItem,
  Ext,
  address,
  shopfiyLineItem,
  DsersExt,
  canReplace,
  types
) {
  let arr = [];
  if (!lineItem) {
    return arr;
  }
  const { country_code = '' } = address;

  const saleIdArr = [];

  let qty = 0;

  if (shopfiyLineItem.fulfillment_status == 'fulfilled') {
    // 完成单
    qty = 0;
  } else {
    qty = shopfiyLineItem.fulfillable_quantity || 0;
  }

  Ext.forEach(item => {
    // 对应关系 variant_id、country code、可下单数量、shopify商品id 相等
    if (
      item.var_id == lineItem.variant_id &&
      item.country == country_code &&
      qty == item.fulfillable_quantity &&
      item.prod_id == lineItem.product_id
    ) {
      // standard mapping
      if ([1, 4].includes(types) && !['MapSta', 'MapBas', 'MapAgency'].includes(item.map_type)) {
        return false;
      } else {
        if (!item.ali_id) {
          item.is_default = true;
          item.map_type = item.map_type == 'MapDef' ? 'MapBas' : item.map_type;
        }
        arr.push(item);
        !saleIdArr.includes(item.sale_id) && saleIdArr.push(item.sale_id);
      }
    }
  });

  // dsers 供应商
  const ExtIsDsers = DsersExt.Ext || [];
  const ExtMap = DsersExt.Map || [];
  const dsersArr = [];

  ExtIsDsers.forEach(item => {
    // 对应关系 variant_id、country code、可下单数量、shopify商品id 相等
    if (
      item.var_id == lineItem.variant_id &&
      item.country == country_code &&
      qty == item.fulfillable_quantity &&
      item.prod_id == lineItem.product_id
    ) {
      if (!item.ali_id) {
        item.is_default = true;
        item.map_type = item.map_type == 'MapDef' ? 'MapBas' : item.map_type;
      }
      item.is_ver = true;
      dsersArr.push(item);
    }
  });

  if (dsersArr.length) {
    dsersArr.forEach(item => {
      arr.forEach((ext, i) => {
        const key =
          ExtMap[`${ext.prod_id}:${ext.ali_id}:${ext.sku}:${ext.var_id}`];
        if (!canReplace) {
          return;
        }
        if (
          key &&
          key.Sku == item.sku &&
          key.SupplierProductId == item.ali_id &&
          canOrderPlace(lineItem)
        ) {
          if (item.map_type == 'MapBas') {
            if (ext.is_default == item.is_default) {
              arr.splice(i, 1, item);
            }
          } else {
            arr.splice(i, 1, item);
          }
        }
      });
    });
  }

  const noRepeatSkuArr = [];
  // 一个lineitem 里的sp 不可能有相同sku的 所以以此依据去重
  arr = arr.filter(sp => {
    const skuKey = `${sp.ali_id}+_+${sp.sku}`;
    const flag = noRepeatSkuArr.includes(skuKey);
    let flag2 = true;
    if (!flag) {
      noRepeatSkuArr.push(skuKey);
    } else {
      flag2 = false;
    }

    return flag2;
  });

  return arr;
}

// 可不可以下单
function canOrderPlace(lineItem) {
  const { status, sp } = lineItem;
  const arr = [2, 32];
  let flag = false;

  arr.forEach(item => {
    if (status & item) {
      flag = true;
    }
  });

  if (!flag && sp) {
    sp.forEach(item => {
      if (item.status == 6 && item.platform_order_id) {
        flag = true;
      }
    });
  }

  return flag;
}

// 获取主供应商
function getUseExt(lineItem, extList) {
  let obj = {};
  if (extList.length == 0 || !lineItem) {
    return obj;
  }
  extList.forEach(item => {
    if (item.map_type == 'MapBas' && item.is_default) {
      obj = { ...item };
    }
    if (item.map_type == 'MapBog' && item.is_base) {
      obj = { ...item };
    }
    // standard mapping
    if (['MapSta', 'MapAdv'].includes(item.map_type)) {
      obj = { ...item };
    }
  });
  return obj;
}

// 获取物流成本
function getFreightCost(freight_def, freights) {
  // console.log(freight_def, freights, 'agency shipping list')
  let v = 0;
  if (!freight_def || !freights || freights.length == 0) {
    return `${v}`;
  }
  freights.forEach(item => {
    if (item.serviceName == freight_def) {
      const { freightAmount = item.standardFreightAmount } = item;
      v = freightAmount.value || 0;
    }
  });
  // console.log(v, 'agency shipping cost');
  return `${v}`;
}

/**
 * 从新计算订单上的商品数量
 * @param {*} shopfiyLineItem detail.line_items里的一条
 * @param {*} ext 这个订单对应的ae供应商
 * @param {*} detail shopify订单详情
 * @param {*} tabStatus 订单页当前tab pending 还是awaiting order
 * @param {*} sp 当前订单
 * @param {*} transactions 下单过程
 * @param {*} extFulfillments 在dsers手动fulfilled的line_item的id
 */
function getQty(
  shopfiyLineItem,
  ext,
  detail,
  tabStatus,
  sp = {},
  transactions,
  extFulfillments = []
) {
  const { refunds, fulfillments } = detail;
  let n = sp.quantity || 0;

  if (ext === undefined && ![3, 4].includes(sp.status)) {
    // other单 保存过 又在shopify改数量 更新sp数量
    if (
      n != shopfiyLineItem.fulfillable_quantity &&
      shopfiyLineItem.fulfillable_quantity
    ) {
      n = shopfiyLineItem.fulfillable_quantity;
    }
  }

  let n2 = 0;
  // 有shopify取消数据 并且不是ae取消 并且订单状态是6
  if (refunds && !isAECanceled(sp, transactions) && sp.status === 6) {
    refunds.forEach(item => {
      if (!item.refund_line_items) {
        return;
      }
      item.refund_line_items.forEach(re => {
        if (re.line_item_id === shopfiyLineItem.id) {
          n2 += re.quantity;
        }
      });
    });
    n = n2 || n;
  }

  // 如果在dsers手动完成就等于总数量
  if (extFulfillments.includes(shopfiyLineItem.id)) {
    n = shopfiyLineItem.quantity;
  }

  // 如果没有ae供应商 并且订单状态是 awaiting payment || awaiting shipment
  if (ext === undefined || [3, 4].includes(sp.status)) {
    return n;
  }

  n =
    ext.quantity == undefined
      ? shopfiyLineItem.fulfillable_quantity || shopfiyLineItem.quantity || 0
      : ext.quantity;

  if (shopfiyLineItem.fulfillment_status == 'fulfilled') {
    n = sp.quantity || shopfiyLineItem.quantity;
  }

  // pengding状态的fulfillments页算作canceled订单
  if (tabStatus === 6 && fulfillments) {
    fulfillments.forEach((item, i) => {
      if (i === 0 && item.status === 'pending') {
        item.line_items.forEach(li => {
          if (li.id === shopfiyLineItem.id) {
            n = li.quantity;
          }
        });
      }
    });
  }

  // 在shopify手动fulfilled状态是success 才算作完成单
  if (tabStatus == 5 && shopfiyLineItem.fulfillment_status !== 'fulfilled') {
    let partialNum = 0;
    // if( shopfiyLineItem.fulfillment_status && shopfiyLineItem.fulfillment_status == 'partial' && fulfillments ){
    if (fulfillments) {
      fulfillments.forEach(item => {
        if (item.status != 'success') {
          return;
        }
        item.line_items.forEach(line => {
          if (line.id == shopfiyLineItem.id) {
            partialNum += line.quantity;
          }
        });
      });
    }

    // bogo mapping || bundle mapping 可以设置数量 意思是shopify买一个 实际在dsers对应几个 ext.fulfillable_quantity是shopify数量 ext.quantity是dsers mapping设置的数量 用这个比例乘一下shopify数量
    if (partialNum > 0) {
      const scaleNum = ext.quantity / ext.fulfillable_quantity;
      if (scaleNum > 0) {
        n = partialNum * scaleNum;
      } else {
        n = partialNum;
      }
    }
  }

  // shopify取消就取shopify取消的数量
  if (tabStatus == 6 && refunds) {
    let refundsNum = 0;
    refunds.forEach(item => {
      if (!item.refund_line_items) {
        return;
      }
      item.refund_line_items.forEach(re => {
        if (re.line_item_id == shopfiyLineItem.id) {
          refundsNum += re.quantity;
        }
      });
    });

    // ae取消就取ae取消的数量 ae取消数量优先
    if (
      sp &&
      sp.status == 6 &&
      shopfiyLineItem.fulfillable_quantity &&
      isAECanceled(sp, transactions)
    ) {
      refundsNum = shopfiyLineItem.fulfillable_quantity;
    }

    // bogo mapping || bundle mapping 可以设置数量 意思是shopify买一个 实际在dsers对应几个 ext.fulfillable_quantity是shopify数量 ext.quantity是dsers mapping设置的数量 用这个比例乘一下shopify数量
    if (refundsNum > 0) {
      const scaleNum = ext.quantity / ext.fulfillable_quantity;
      if (scaleNum > 0) {
        n = refundsNum * scaleNum;
      } else {
        n = refundsNum;
      }
    }
  }

  // 如果在dsers手动完成就等于总数量
  if (extFulfillments.includes(shopfiyLineItem.id)) {
    n = shopfiyLineItem.quantity;
  }

  return n;
}

// 是不是ae canceled
function isAECanceled(sp, transactions) {
  let flag = false;

  if (!transactions) {
    return flag;
  }

  if (!sp.platform_order_id) {
    return flag;
  }

  const record = [];
  let lastRecord;
  transactions.forEach(tr => {
    const {
      order_info: {
        [sp.platform_order_id]: { order_status, logistics_status } = {}
      } = {}
    } = tr;

    if (order_status) {
      record.push(tr.order_info[sp.platform_order_id]);
    }
  });

  if (record.length) {
    lastRecord = record[record.length - 1];
    if (
      lastRecord.order_status === 'FINISH' &&
      lastRecord.logistics_status === 'NO_LOGISTICS'
    ) {
      flag = true;
    }

    if (lastRecord.order_status === 'IN_CANCEL') {
      flag = true;
    }
  }

  return flag;
}

// 获取sp状态
function getSpStatus(
  sp,
  lineItem,
  shopfiyLineItem,
  detail,
  tabStatus,
  extFulfillments = [],
  si
) {
  const {
    refunds,
    fulfillments,
    financial_status,
    cancel_reason,
    cancelled_at
  } = detail;
  let n = 0;

  if (sp.status == undefined && shopfiyLineItem.fulfillable_quantity) {
    if (lineItem.status & 1) {
      sp.status = 1;
    } else {
      sp.status = 2;
    }

    if (lineItem.status == 32) {
      sp.status = 7;
    }

    if (tabStatus == 6 && lineItem.sp) {
      // 下完单在ae取消 Ext又都换新的了 让新生成的sp状态等于后台返回的sp状态
      sp.status = lineItem.sp[si].status || 2;
    }
  }

  n = sp.status || tabStatus;

  if (
    shopfiyLineItem.fulfillable_quantity === undefined &&
    lineItem.status === 2
  ) {
    n = 2;
  }

  if (
    shopfiyLineItem.fulfillable_quantity === undefined &&
    lineItem.status === 1
  ) {
    n = 1;
  }

  if (
    shopfiyLineItem.fulfillment_status == 'fulfilled' &&
    !getHasOnlyCanceled(shopfiyLineItem, detail)
  ) {
    return 5;
  }

  if (
    shopfiyLineItem.fulfillment_status == 'fulfilled' &&
    !getHasCanceled(shopfiyLineItem, detail)
  ) {
    return 5;
  }

  if (!shopfiyLineItem.fulfillable_quantity && refunds) {
    refunds.forEach(re => {
      if (!re.refund_line_items) {
        return;
      }
      re.refund_line_items.forEach(item => {
        if (item.line_item_id == shopfiyLineItem.id) {
          n = 6;
        }
      });
    });
  }

  if (tabStatus == 5) {
    if (
      shopfiyLineItem.fulfillment_status &&
      ['partial', 'fulfilled'].includes(shopfiyLineItem.fulfillment_status)
    ) {
      n = 5;
    }
  }

  if (
    tabStatus == 6 &&
    hasCanceled(financial_status, cancel_reason, cancelled_at, lineItem)
  ) {
    n = 6;
  }

  if (tabStatus == 6 && refunds) {
    refunds.forEach(item => {
      if (
        !item.refund_line_items &&
        ['voided', 'refunded'].includes(financial_status) &&
        lineItem.status == 16
      ) {
        n = 6;
      }
      if (!item.refund_line_items) {
        return;
      }
      item.refund_line_items.forEach(re => {
        if (re.line_item_id == shopfiyLineItem.id) {
          n = 6;
        }
      });
    });
  }

  if (
    tabStatus == 5 &&
    lineItem.status === 2048 &&
    detail.fulfillment_status === 'fulfilled'
  ) {
    n = 5;
  }

  // if (tabStatus == 2 && shopfiyLineItem.fulfillable_quantity && n != 5) {
  //   n = 2;
  // }

  if (tabStatus == 5 && extFulfillments.includes(lineItem.lineitem_id)) {
    n = 5;
  }

  return n;
}

function setSp(
  sp,
  ext,
  lineItem,
  shopfiyLineItem,
  detail,
  tabStatus,
  fulfillments,
  i,
  transactions,
  dataItem = 0
) {
  sp.store_info = ext.store_info ||  {store_name:'',store_url:''},
  sp.platform_product_id = ext.ali_id;
  sp.platform_sku = ext.sku;
  sp.logistics_company = ext.freight_def;
  sp.freights = ext.freights;
  sp.already_set_freights = ext.product_freight;
  if(sp.logistics_company && !sp?.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = '';
  }

  sp.quantity = getQty(
    shopfiyLineItem,
    ext,
    detail,
    tabStatus,
    undefined,
    transactions,
    fulfillments
  );
  sp.cost = ext.cost;
  sp.freight_cost = getFreightCost(ext.freight_def, ext.freights);
  sp.sale_id = ext.sale_id;
  sp.is_default = ext.is_default;
  sp.is_base = ext.is_base;
  sp.status = dataItem || getSpStatus(
    sp,
    lineItem,
    shopfiyLineItem,
    detail,
    tabStatus,
    fulfillments,
    i
  )
  
  sp.title = shopfiyLineItem.title || '';
  sp.sku_img = ext.sku_img || ext.var_img;
  sp.variant_title = ext.variant_title || shopfiyLineItem.variant_title;
  sp.is_ver = ext.is_ver;
}

function setAgencySp (
  sp,
  ext,
  lineItem,
  shopfiyLineItem,
  detail,
  tabStatus,
  fulfillments,
  i,
  transactions,
  dataItem = 0,
  agencyList
) {
  sp.store_info = ext.store_info ||  {store_name:agencyList?.find(i => `${i.id}` === ext.sale_id)?.agency_name,store_url:''},
  sp.platform_product_id = ext.ali_id;
  sp.agency_id = ext.agency_id;
  sp.platform_sku = ext.sku;

  if(!ext.freights?.length && false) {
    sp.logistics_company = 'Other';
    sp.freights = [
      {
        company: "Seller's shipping method",
        freightAmount: {
          currency: 'USD',
          formatedAmount: '0'
        },
        serviceName: 'Other',
        standardFreightAmount: {
          currency: 'USD',
          formatedAmount: '0'
        },
        time: '',
        tracking: false
      }
    ];
  } else {
    sp.freights = ext.freights;
    sp.freights.forEach(i => {
      if(!i.serviceName && i.id) {
        i.serviceName = `${i.id}`;
      }
    });
    sp.logistics_company = sp.logistics_company || ext.freight_def;
  }

  // sp.logistics_company = 'Other';
  // sp.freights = [{
  //     "company": "Seller's shipping method",
  //     "freightAmount": {
  //         "currency": "USD",
  //         "formatedAmount": "0"
  //     },
  //     "serviceName": "Other",
  //     "standardFreightAmount": {
  //         "currency": "USD",
  //         "formatedAmount": "0"
  //     },
  //     "time": "",
  //     "tracking": true
  // }];
  sp.already_set_freights = ext.product_freight;
  if(sp.logistics_company && !sp?.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = '';
  }

  if(sp.freights?.length && !sp.logistics_company) {
    sp.logistics_company = sp.freights[0]?.serviceName;
  }

  sp.quantity = getQty(
    shopfiyLineItem,
    ext,
    detail,
    tabStatus,
    undefined,
    transactions,
    fulfillments
  );
  // sp.cost = ext.cost;
  sp.freight_cost = getFreightCost(sp.logistics_company, ext.freights);
  sp.cost = ext.cost;
  // sp.freight_cost = '0';
  sp.sale_id = ext.sale_id;
  sp.is_default = ext.is_default;
  sp.is_base = ext.is_base;
  sp.status = dataItem || getSpStatus(
    sp,
    lineItem,
    shopfiyLineItem,
    detail,
    tabStatus,
    fulfillments,
    i
  )
  
  sp.title = shopfiyLineItem.title || '';
  sp.sku_img = ext.sku_img || ext.var_img;
  sp.variant_title = ext.variant_title || shopfiyLineItem.variant_title;
  sp.is_ver = ext.is_ver;
}

function replaceSp(sp, ext, shopfiyLineItem, detail) {
  sp.platform_product_id = ext.ali_id;
  sp.store_info = ext.store_info ||  {store_name:'',store_url:''},
  sp.platform_sku = ext.sku;
  // 更新供应商时，应该检查曾经保存的物流是否还在最新的物流列表中，如果不在需要重新按默认物流设置或者置空
  sp.logistics_company =
    (sp.logistics_company &&
      ext?.freights?.find(i => i.serviceName === sp.logistics_company) &&
      sp.logistics_company) ||
    ext.freight_def ||
    '';
  sp.freights = ext.freights;
  // 检查重新设置后的物流是否在最新的物流列表中，如果不在物流应该置空
  if(sp.logistics_company && !sp?.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = '';
  }
  sp.already_set_freights = ext.product_freight;
  // sp.quantity = ext.quantity || shopfiyLineItem.quantity; // ext.quantity是0说明没有可以下单的 那就是shopify canceled
  sp.cost = ext.cost;
  sp.freight_cost = getFreightCost(
    sp.logistics_company || ext.freight_def,
    ext.freights
  );
  sp.sale_id = ext.sale_id;
  sp.title = shopfiyLineItem.title || '';
  sp.sku_img = ext.sku_img || ext.var_img;
  sp.variant_title = ext.variant_title || shopfiyLineItem.variant_title;
  sp.is_default = ext.is_default;
  sp.is_base = ext.is_base;
  sp.is_ver = ext.is_ver;
  sp.status = getReplaceSpStatus(sp, shopfiyLineItem, detail);
}

function replaceAgencySp (sp, ext, shopfiyLineItem, detail, agencyList) {
  sp.agency_id = ext.agency_id;
  sp.store_info = ext.store_info ||  {store_name:agencyList?.find(i => `${i.id}` === ext.sale_id)?.agency_name,store_url:''};
  // sp.platform_sku = ext.sku;
  // 更新供应商时，应该检查曾经保存的物流是否还在最新的物流列表中，如果不在需要重新按默认物流设置或者置空
  // sp.logistics_company = 'Other';
  // sp.freights = [{
  //     "company": "Seller's shipping method",
  //     "freightAmount": {
  //         "currency": "USD",
  //         "formatedAmount": "0"
  //     },
  //     "serviceName": "Other",
  //     "standardFreightAmount": {
  //         "currency": "USD",
  //         "formatedAmount": "0"
  //     },
  //     "time": "",
  //     "tracking": true
  // }];

  if(!ext.freights?.length && false) {
    // sp.logistics_company = 'Other';
    // sp.freights = [{
    //     "company": "Seller's shipping method",
    //     "freightAmount": {
    //         "currency": "USD",
    //         "formatedAmount": "0"
    //     },
    //     "serviceName": "Other",
    //     "standardFreightAmount": {
    //         "currency": "USD",
    //         "formatedAmount": "0"
    //     },
    //     "time": "",
    //     "tracking": false
    // }];
  } else {
    sp.freights = ext.freights;
    sp.freights.forEach(i => {
      if(!i.serviceName && i.id) {
        i.serviceName = `${i.id}`;
      }
    });
    sp.logistics_company = sp.logistics_company || ext.freight_def;
  }

  // 检查重新设置后的物流是否在最新的物流列表中，如果不在物流应该置空
  if(sp.logistics_company && !sp?.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = '';
  }

  if(!sp.logistics_company && sp.freights?.length) {
    sp.logistics_company = sp.freights[0]?.serviceName;
  }

  sp.already_set_freights = ext.product_freight;
  // sp.quantity = ext.quantity || shopfiyLineItem.quantity; // ext.quantity是0说明没有可以下单的 那就是shopify canceled
  // sp.cost = ext.cost;
  sp.cost = ext.cost || '0';
  sp.freight_cost = getFreightCost(
    sp.logistics_company || ext.freight_def,
    ext.freights
  );
  // sp.freight_cost = '0';
  sp.sale_id = ext.sale_id;
  sp.title = shopfiyLineItem.title || '';
  sp.sku_img = ext.sku_img || ext.var_img;
  sp.variant_title = ext.variant_title || shopfiyLineItem.variant_title;
  sp.is_default = ext.is_default;
  sp.is_base = ext.is_base;
  sp.is_ver = ext.is_ver;
  sp.status = getReplaceSpStatus(sp, shopfiyLineItem, detail);
  // if(sp.platform_order_id || sp.platform_sku)
  sp.platform_product_id = ext.ali_id;
  sp.platform_sku = ext.sku;
  
}

function getReplaceSpStatus(sp, shopfiyLineItem, detail) {
  let n = sp.status;

  if (n != 0) {
    return n;
  }

  const { fulfillments = [] } = detail;

  fulfillments.forEach(ful => {
    // sp是other 在shopify request fulfillment sp的status是0 又配置mapping 把status设为5 ( 目前就处理这种情况 )
    if (ful.status != 'pending') {
      return;
    }
    ful.line_items.forEach(lineItem => {
      if (lineItem.id == shopfiyLineItem.id) {
        n = 5;
      }
    });
  });

  return n;
}

// 有没有可能是shopify canceled
export function hasCanceled(
  financial_status,
  cancel_reason,
  cancelled_at,
  lineItem
) {
  let flag = false;
  let has16;

  if (lineItem) {
    has16 = lineItem.status & 16;
  }

  if (cancel_reason && cancelled_at) {
    flag = true;
  }

  if (financial_status === 'voided') {
    flag = true;
  }

  if (financial_status === 'refunded' && has16 === 16) {
    flag = true;
  }

  return flag;
}

// 获取是否是 AE cancel
function getIsAECanceled(sp, transactions) {
  let flag = false;

  if (!transactions || !sp.platform_order_id) {
    return flag;
  }

  transactions.forEach(tr => {
    const {
      order_info: {
        [sp.platform_order_id]: { order_status, logistics_status } = {}
      } = {}
    } = tr;

    if (
      (order_status === 'FINISH' && logistics_status === 'NO_LOGISTICS') ||
      order_status === 'IN_CANCEL'
    ) {
      flag = true;
    }
  });
  return flag;
}

// 两个lineitem 都在shopify有canceled数量 ，一个下单成功 一个ae取消，取消的这个再下单时 会把另一个sp的状态变成6 导致awaiting payment里空白单 目前只针对这个bug  2020-6-22
function getSpStatus3(
  sp,
  lineItem,
  shopfiyLineItem,
  tabStatus,
  transactions = [],
  fulfillments = []
) {
  const aeStatusArr = [];
  let n = sp.status;

  if ([3, 4].includes(n)) {
    return n;
  }

  transactions.forEach(tr => {
    const {
      order_info: { [sp.platform_order_id || 123]: { order_status } = {} } = {}
    } = tr;
    if (order_status) {
      aeStatusArr.push(order_status);
    }
  });

  if (
    tabStatus == 3 &&
    shopfiyLineItem.fulfillable_quantity &&
    sp.platform_order_id &&
    aeStatusArr[aeStatusArr.length - 1] === 'PLACE_ORDER_SUCCESS'
  ) {
    n = 3;
  }

  if (fulfillments.includes(lineItem.lineitem_id)) {
    n = 5;
  }

  return n;
}

/**
 * 从新计算sp的状态
 * @param {*}} sp
 * @param {*} lineItem ext.lineitems里的一条数据
 * @param {*} shopfiyLineItem detail.line_items里的一条数据
 * @param {*} detail shopify订单详情
 * @param {*} tabStatus 订单页处在什么状态 pending 还是 awaiting order
 * @param {*} extFulfillments ext.fulfillments在dsers手动弄成完成单子的lineitem_id
 * @param {*} transactions ae下单记录
 */
function getSpStatus2(
  sp,
  lineItem,
  shopfiyLineItem,
  detail,
  tabStatus,
  extFulfillments,
  transactions
) {
  const {
    refunds, // 在shopify取消的订单的信息
    fulfillments, // 在shopify手动弄成完成订单的信息
    financial_status, // 财务状态
    cancel_reason, // 谁取消的
    cancelled_at // 取消时间
  } = detail;
  let n = sp.status; // 状态

  // 在shopify refund 后台会把lineitem 的状态变成16 但不会改sp sp还是2
  if (refunds && lineItem.status == 16) {
    refunds.forEach(re => {
      if (
        !re.refund_line_items &&
        hasCanceled(financial_status, cancel_reason, cancelled_at) &&
        lineItem.status == 16
      ) {
        n = 6;
      }
      if (!re.refund_line_items) {
        return;
      }
      re.refund_line_items.forEach(item => {
        if (
          item.line_item_id == shopfiyLineItem.id &&
          shopfiyLineItem.fulfillable_quantity == undefined
        ) {
          n = 6;
        }
      });
    });
    // 在dsers手动fulfilled状态是5
    if (
      extFulfillments &&
      tabStatus == 5 &&
      extFulfillments.includes(shopfiyLineItem.id)
    ) {
      return 5;
    }
    if (n == 6) {
      return n;
    }
  }

  // 订单从pending到awaiting order
  if (
    sp.status == 1 &&
    lineItem.status == 2
    // && detail.financial_status == 'paid'
  ) {
    n = 2;
  }

  // 订单从awaiting order 到pending 
  // - 特殊情况，当pending的订单手动fulfill并且rollback后,订单会回到awaiting order。
  // - 此时触发任何订单hook重新计算订单状态后会将订单转移到pending
  // - 如果转移到pending之前保存过sp，则将会出现lineitem.status为1，内层sp的status为2,导致出现空白单
  // - 此处对于这种情况进行修正，将sp status修改为与外层lineitem一致
  if(sp.status === 2 && lineItem.status === 1) {
    n = 1;
  }

  if (tabStatus === 2 && fulfillments && lineItem.status === 2) {
    fulfillments.forEach((item, i) => {
      if (i === 0 && item.status === 'cancelled') {
        item.line_items.forEach(li => {
          if (li.id === shopfiyLineItem.id) {
            n = 2;
          }
        });
      }
    });
  }

  // 有可下单数量 没有订单号(没下过单) lineItem.status(是sp状态的合集)里包含2(未下单状态)
  if (
    tabStatus === 2 &&
    shopfiyLineItem.fulfillable_quantity > 0 &&
    sp.platform_order_id === '' &&
    (lineItem.status & 2) === 2
  ) {
    n = 2;
  }

  // 例如 一个订单2个数量 取消一个 下单成功一个 在canceled tab下是6  在awaiting payment tab下改回3
  if (tabStatus == 3) {
    if (lineItem.status & 4 && sp.platform_order_id) {
      n = 3;
    }
  }

  // 一个lineitem下单失败 在shopify取消部分数量 在canceled保存sp状态变成6 在failed里让它再变成7
  const hasFailedStatus = lineItem.status & 32;
  if (
    tabStatus == 7 &&
    hasFailedStatus === 32 &&
    shopfiyLineItem.fulfillable_quantity > 0
  ) {
    n = 7;
  }

  if (tabStatus != 5 && tabStatus != 6) {
    return n;
  }

  // shopify标记已经完成
  if (shopfiyLineItem.fulfillment_status == 'fulfilled') {
    n = 5;
  }

  // 当前在fulfilled tab下如果有shopify手动fulfilled的数量 就把状态改成5
  if (tabStatus == 5 && fulfillments) {
    fulfillments.forEach(item => {
      // 在shopify手动fulfilled的状态必须是成功状态
      if (item.status != 'success') {
        return;
      }
      item.line_items.forEach(line => {
        // 如果包含当前订单
        if (line.id == shopfiyLineItem.id) {
          n = 5;
        }
      });
    });
  }

  // 当前状态是canceled tab(6) 如果有shopify退款数量 那就把状态变成6
  if (tabStatus == 6 && refunds) {
    refunds.forEach(re => {
      if (!re.refund_line_items) {
        return;
      }
      re.refund_line_items.forEach(item => {
        // 如果包含当前订单
        if (item.line_item_id == shopfiyLineItem.id) {
          n = 6;
        }
      });
    });
  }

  if (tabStatus == 6 && fulfillments) {
    fulfillments.forEach((item, i) => {
      // 在shopify手动fulfilled的状态是pending的订单也算作canceled
      if (i === 0 && item.status === 'pending') {
        item.line_items.forEach(li => {
          if (li.id === shopfiyLineItem.id) {
            n = 6;
          }
        });
      }
    });
  }

  // financial_status, cancel_reason, cancelled_at 这三个值如果符合条件也是canceled单
  if (
    tabStatus == 6 &&
    hasCanceled(financial_status, cancel_reason, cancelled_at)
  ) {
    n = 6;
  }

  return n;
}

// 外层Ext cost freight_def变化更新
function canUpdateCost(ext, sp) {
  if (ext.cost) {
    sp.cost = ext.cost;
  }
  // 仅更新价格时，也应该检查曾经保存的物流是否还在最新的物流列表中，如果不在需要重新按默认物流设置或者置空
  if (!sp.logistics_company || !ext?.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = ext.freight_def || '';
  }
  sp.freights = ext.freights;
  // 检查重新设置后的物流是否在最新的物流列表中，如果不在物流应该置空
  if(sp.logistics_company && !sp?.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = '';
  }
  sp.already_set_freights = ext.product_freight;
  sp.is_ver = ext.is_ver;
  sp.is_base = ext.is_base;
  sp.freight_cost = getFreightCost(sp.logistics_company, ext.freights);
}

function canUpdateAgency (ext, sp, shopfiyLineItem, extLineitem) {
  if(!extLineitem?.agency_id) return;

  if(!ext.freights?.length && false) {
    // sp.logistics_company = 'Other';
    // sp.freights = [{
    //     "company": "Seller's shipping method",
    //     "freightAmount": {
    //         "currency": "USD",
    //         "formatedAmount": "0"
    //     },
    //     "serviceName": "Other",
    //     "standardFreightAmount": {
    //         "currency": "USD",
    //         "formatedAmount": "0"
    //     },
    //     "time": "",
    //     "tracking": false
    // }];
  } else {
    sp.freights = ext.freights;
    sp.freights.forEach(i => {
      if(!i.serviceName && i.id) {
        i.serviceName = `${i.id}`;
      }
    });
    sp.logistics_company = sp.logistics_company || ext.freight_def;
  }

  // 选择的物流不存在了
  if(!sp.freights?.find(i => i.serviceName === sp.logistics_company)) {
    sp.logistics_company = '';
    if(sp.freights?.length) {
      sp.logistics_company = sp.freights[0]?.serviceName;
    }
  }

  // if(sp.freights?.length !== 1 || sp.freights[0].serviceName !== 'Other' || sp.logistics_company !== 'Other') {
  //   sp.logistics_company = 'Other';
  //   sp.freights = [{
  //       "company": "Seller's shipping method",
  //       "freightAmount": {
  //           "currency": "USD",
  //           "formatedAmount": "0"
  //       },
  //       "serviceName": "Other",
  //       "standardFreightAmount": {
  //           "currency": "USD",
  //           "formatedAmount": "0"
  //       },
  //       "time": "",
  //       "tracking": true
  //   }];
  // }

  sp.title = shopfiyLineItem.title || '';
  sp.sku_img = ext.sku_img || ext.var_img || sp.sku_img;
  sp.variant_title = ext.variant_title || shopfiyLineItem.variant_title;
  sp.sale_id = sp.sale_id;
  sp.cost = ext.cost || '0'
  // agency订单 freight cost来自供应商配置或手动上传数据 不使用mapping中的选择
  sp.freight_cost = getFreightCost(
    sp.logistics_company || ext.freight_def,
    ext.freights
  );
  // console.log('updateAgencyTitle');
  // sp.store_info = {store_name:agencyList?.find(i => `${i.id}` === ext.sale_id)?.agency_name,store_url:''};

}

function setAgencyInfo (sp, agencyList) {

}

// extList 里的所有ae产品id都是空的
function extListAllEmptyAliId(extList) {
  let flag = true;
  extList.forEach(ext => {
    if (ext.ali_id) {
      flag = false;
    }
  });
  return flag;
}

// 该子订单有没有被shopify canceled
function getHasCanceled(shopfiyLineItem, detail) {
  const {
    refunds, // 在shopify退的数据 比如买2个退一下
    fulfillments, // 在shopify手动弄成已完成的订单的数据 比如买2个把1个弄成已完成
    financial_status, // 财务状况
    cancel_reason, // 谁取消的订单
    cancelled_at // 取消时间
  } = detail;
  let flag = false;

  refunds &&
    refunds.forEach(item => {
      if (
        !item.refund_line_items &&
        hasCanceled(financial_status, cancel_reason, cancelled_at)
      ) {
        flag = true;
      }
      if (!item.refund_line_items) {
        return;
      }
      item.refund_line_items.forEach(re => {
        if (re.line_item_id == shopfiyLineItem.id && re.quantity) {
          flag = true;
        }
      });
    });

  fulfillments &&
    fulfillments.forEach(item => {
      item.status == 'success' &&
        item.line_items.forEach(line => {
          if (line.id == shopfiyLineItem.id && line.quantity) {
            flag = true;
          }
        });
    });

  return flag;
}

function getHasOnlyCanceled(shopfiyLineItem, detail) {
  const { refunds, financial_status, cancel_reason, cancelled_at } = detail;
  let flag = false;

  if (hasCanceled(financial_status, cancel_reason, cancelled_at)) {
    flag = true;
  }

  refunds &&
    refunds.forEach(item => {
      if (!item.refund_line_items) {
        return;
      }
      item.refund_line_items.forEach(re => {
        if (re.line_item_id == shopfiyLineItem.id && re.quantity) {
          flag = true;
        }
      });
    });

  return flag;
}

function getHasOnlyOther(lineItem, extPidArr) {

  if(lineItem?.sp?.length === 1 && supplyPlatformEquals(lineItem.platform_type, 5)) {
    return true;
  }

  // lineItem 里的sp是other单 最外层Ext没有匹配的供应商,返回的是空数组  导致把other状态的sp也筛除掉,返回sp是空数组 导致无法edit
  let flag = false;
  if (
    lineItem.sp &&
    lineItem.sp.length == 1 &&
    !lineItem.sp[0].platform_product_id &&
    extPidArr.length == 0
  ) {
    flag = true;
  }
  return flag;
}

// 副供应商下单成功 然后在ae canceled， 这时 主供应商状态是2 副供应商是6，然后用户把副供应商mapping删除  我就把副供应商sp 删除了， 剩下主供应商状态是2 所以是空白单
// 目前只针对这个bug
function spShouldBeStatus(lineItem, tabStatus) {
  let status = 0;
  let orderNo = '';
  if (![6, 7].includes(tabStatus) || lineItem.sp.length < 2) {
    return [status, orderNo];
  }

  lineItem.sp.forEach(sp => {
    if (sp.status == tabStatus) {
      status = tabStatus;
      orderNo = sp.platform_order_id || '';
    }
  });

  return [status, orderNo];
}

// 已有sp的情况下换mapping 针对 basMap 判断用不用新增sp 只换了mapping商品没有新增就只更新
function hasBasMap(lineItem, extSp, oneExt) {
  // if (lineItem.sp.length == 2) {
  //   return false;
  // }

  let flag = true;

  lineItem.sp.forEach(sp => {
    if (sp.is_default == oneExt.is_default) {
      flag = false;
    }
  });

  extSp.forEach(sp => {
    if (sp.is_default == oneExt.is_default) {
      flag = false;
    }
  });

  return flag;
}
// detail | ext
function getLineitemFulfillmentStatus(
  detail = {},
  extFulfillments = [],
  lineitem = {}
) {
  const { fulfillments: shopifyFulfillments = [] } = detail;
  const { id } = lineitem;
  return (
    extFulfillments?.includes(id) ||
    !!shopifyFulfillments?.find(fulfillmentRecord => {
      return (
        fulfillmentRecord?.status !== 'cancelled' &&
        fulfillmentRecord?.line_items?.find(item => item.id === id)
      );
    })
  );
}

// 用ae返回的商品价格替换sp里的商品价格和物流费用
// lineItem是ext.lineitems里的一条数据
function setSpCost(sp, transactions, lineItem) {
  if (!sp.platform_order_id || !transactions) {
    return;
  }

  transactions.forEach(item => {
    const {
      // order_info: {
      //   [sp.platform_order_id]: {
      //     child_order_list: { aeop_child_order_info = [] } = {}
      //   } = {}
      // } = {},
      items = []
    } = item;
    // console.log(lineItem)
    items.forEach(transItem => {
      const { sp: transSp = [] } = transItem;
      if (transItem.lineitem_id !== lineItem.lineitem_id) {
        return;
      }
      transSp.forEach(transSpItem => {
        if (
          transSpItem.platform_product_id === sp.platform_product_id &&
          transSpItem.platform_sku === sp.platform_sku
        ) {
          // console.log(transSpItem.cost);
          sp.cost = transSpItem.cost;
        }
      });
    });
    // aeop_child_order_info.forEach(info => {
    //   if (`${info.product_id}` === sp.platform_product_id && info.product_price) {
    //     sp.cost = info.product_price.amount;
    //   }
    // });
  });
}

function getQty2(sp, tabStatus, transactions = [], lineitemId) {
  let qty = sp.quantity;

  if (tabStatus != 3 || sp.status !== 3) {
    return qty;
  }

  let recordItems = [];
  let recordSp;
  console.log('transactions2' , transactions)
  transactions.forEach(tr => {
    if(!tr.hasOwnProperty('order_info')){
      return
    }

    if(!tr.order_info[sp.platform_order_id]){
      tr.order_info[sp.platform_order_id] = {
        order_status: '',
        logistics_status: ''
      }
    }
    const {
      items = [],
      order_info: { [sp.platform_order_id || 123]: { order_status } = {} } = {}
    } = tr;

    if (order_status === 'PLACE_ORDER_SUCCESS') {
      recordItems = items;
    }
  });

  recordItems.forEach(re => {
    if (re.lineitem_id !== lineitemId) {
      return;
    }
    if (!recordSp) {
      recordSp = re.sp.find(
        li =>
          li.platform_sku === sp.platform_sku &&
          li.platform_product_id === sp.platform_product_id
      );
    }
  });

  if (recordSp) {
    qty = recordSp.quantity;
  }

  return qty;
}

/**
 * 当前订单对应ae供应商第几个
 * @param {*} sp 当前订单
 * @param {*} extList ae供应商
 * @param {*} si 当前订单的索引
 */
function getExtIndex(sp, extList, si) {
  let index = si;
  // 没有供应商
  if (!extList[0]) {
    return index;
  }

  // 如果是 bogo || bundle  bogo的目的是买一赠一 一个shopify子订单的商品如果是bogo 就会有2个供应商 要找到对应哪个
  if (['MapBog', 'MapBun'].includes(extList[0].map_type)) {
    extList.forEach((item, i) => {
      // 根据ae商品id 和 sku 找到对应关系
      if (
        sp.platform_product_id === item.ali_id &&
        sp.platform_sku === item.sku
      ) {
        index = i;
      }
    });
  }
  return index;
}

/**
 * 显示供应商信息
 * @param {*} sp            节点信息
 * @param {*} address       地址
 * @param {*} ActionEditExt 编辑过得商品信息 
 * @param {*} extList  EXT信息
 * @returns    供应商信息
 */
const showSupplierMes = (sp,address,ActionEditExt,extList,Ext,transactions) =>{
  let returnValue = null;
  let supplierEditPro = `${sp.platform_product_id}|${address.country_code ? address.country_code :''}|Agency`
  if(ActionEditExt[supplierEditPro]){
    returnValue =  ActionEditExt[supplierEditPro].store_info
  }else{
    if(!extList.some((item)=>item.ali_id == sp.platform_product_id)){
        extList = JSON.parse(JSON.stringify(Ext))
     }
     extList.forEach((ext, ei) => {
      if (
        ext.ali_id == sp.platform_product_id && ext.country == address.country_code
        ) {
           returnValue =  ext.store_info
        }
    })
  }
  if(!returnValue){
    transactions?.forEach((item)=>{
        if(!item?.order_info){
          return
        }
        Object.keys(item.order_info)?.forEach((itemChild)=>{
           if(itemChild == sp.platform_order_id){
                 returnValue = item.order_info[itemChild]?.store_info
           }
        })
    })
  }
  return returnValue
}
/**
 * 根据mapping数据 shopify订单数据 和ae下单数据 获取和更新子订单数据
 * @param {*} lineItem // ext.lineitems里的一条数据
 * @param {*} extList // mapping数据
 * @param {*} shopfiyLineItem // detail.line_items里的一条数据
 * @param {*} address
 * @param {*} detail
 * @param {*} tabStatus // 订单页是什么状态pending 还是awaiting order
 * @param {*} fulfillments // 在dsers手动fulfilled的订单的lineitem_id的数组
 * @param {*} transactions // 下单过程数据
 * @param {*} isPendingFulfillments // 是不是在shopify pending fulfillment
 * @param {*} extErr // 下单错误类型
 */
function getChildSp(
  lineItem,
  ActionEditExt,
  Ext,
  extList,
  shopfiyLineItem,
  address,
  detail,
  tabStatus,
  fulfillments,
  transactions,
  isPendingFulfillments,
  extErr,
  dataItem = 0,
  // agency 列表
  agencyList,
  isShopifyCanceled
) {
  // if (detail.order_number === 1120) {
  //   // debugger;
  //   console.log('🧊', tabStatus);
  // }
  // Awaiting payment   Awaiting shipment 状态 有sp直接返回 不用考虑Ext

  if (lineItem.sp && [3, 4].includes(tabStatus)) {
    lineItem.sp.forEach(item => {
      // 状态是10 是等待ae取消的状态
      if (item.status === 10) {
        item.status = 4;
      }
      item.status = getSpStatus3(
        item,
        lineItem,
        shopfiyLineItem,
        tabStatus,
        transactions,
        fulfillments
      ); // getSpStatus3上有注释
      item.quantity = getQty2(
        item,
        tabStatus,
        transactions,
        lineItem.lineitem_id
      ); // 获取实际下单数量
      setSpCost(item, transactions, lineItem); // // 用ae返回的商品价格替换sp里的商品价格和物流费用 ae返回的比较准确  
       // 显示供应商信息
      let supplierResult  =  showSupplierMes(item,address,ActionEditExt,extList,Ext,transactions)
     if(supplierResult){
       item.store_info =  supplierResult
     }
      // if (supplyPlatformEquals(lineItem.platform_type, 5)) {
      //   const agencyUseExt = extList.find(i => i.map_type === 'MapAgency');
      //   if (agencyUseExt || lineItem.agency_id) {
      //     canUpdateAgency(agencyUseExt || {}, item, shopfiyLineItem, lineItem);
      //   }
      // }
    
    });
    return lineItem.sp; // awaiting payment awaiting shipment不用更新sp数据直接返回
  }

  // 有sp数据就更新sp
  if (lineItem.sp && !dataItem) {
    const spShouldBe = spShouldBeStatus(lineItem, tabStatus); // spShouldBeStatus方法上有注释 返回 [订单状态, 订单号]
    const shouldBeStatus = spShouldBe[0]; // 订单状态
    const shouldBePlatformOrderId = spShouldBe[1]; // 订单号

    // eslint-disable-next-line
    for (const [si, sp] of lineItem.sp.entries()) {
      // 订单状态是3||4 并且没被shopify取消
      if (
        [3, 4].includes(sp.status) &&
        !getHasCanceled(shopfiyLineItem, detail) &&
        !(detail.cancelled_at && detail.cancel_reason)
      ) {
        setSpCost(sp, transactions, lineItem); // 更新价格和物流费用
        continue;
      }

      // 从新计算sp的状态
      let spStatus = getSpStatus2(
        sp,
        lineItem,
        shopfiyLineItem,
        detail,
        tabStatus,
        fulfillments,
        transactions
      );
      console.log('spStatus', spStatus)
      // 如果在dsers手动fulfilled 那就是完成单
      
      if(spStatus === 11 && tabStatus === 9){
        sp.status = 5
      }

      if (
        fulfillments?.includes(lineItem.lineitem_id) &&
        !(detail.cancel_reason && detail.cancelled_at && tabStatus === 6)
      ) {
        spStatus = 5;
      }

      // shopify同步到dsers都是Awaiting order 手动fulfilled一些 其他都会存上status=2 再在shopify把状态是2的refund 后台会把lineitem 的状态变成16 但不会改sp sp还是2
      if (lineItem.status == 16 && spStatus == 6) {
        sp.status = 6;
      }

      // sp的状态不等于当前状态 并且从新计算的spStatus等于当前状态( 2个数量一个被shopify fulfilled 一个awaiting order就会出现 ) 就把状态改成新的
      if (sp.status != tabStatus && spStatus == tabStatus) {
        sp.status = spStatus;
      }

      // 订单由pending变成awaiting order 把状态改成2
      if (
        sp.status === 1 &&
        lineItem.status === 2 &&
        spStatus === 2 &&
        tabStatus === 1
      ) {
        sp.status = spStatus;
      }

      // 在shopify fulfilled的订单改成5
      if (
        shopfiyLineItem.fulfillment_status == 'fulfilled' &&
        !(detail.cancel_reason && detail.cancelled_at && tabStatus === 6)
      ) {
        sp.status = 5;
      }

      // 订单在shopify手动fulfilled 再return, 再在dsers回滚 同步到shopify是不能回滚成功的 要把订单放到canceled里 返回的lineitem状态是8192
      // && !shopfiyLineItem?.fulfillment_status 当订单不是在已完成的情况下退费才会更改为6，也就是该订单实在已发货未完成时退费会变为取消订单
      if (lineItem.status === 8192 && !shopfiyLineItem?.fulfillment_status) {
        sp.status = 6;
      }

      if(lineItem.status == 32){
        sp.status = 7;
      }

      sp.quantity = getQty(
        shopfiyLineItem,
        extList[getExtIndex(sp, extList, si)],
        detail,
        tabStatus,
        sp,
        transactions,
        fulfillments
      );
    }

    // if( extList.length == 0 || extListAllEmptyAliId( extList ) ){  // 用户删除mapping变成other 会导致sp还是ae 所以注释。  之前为啥加这段忘了
    //   return lineItem.sp;
    // }

    const spAliPidArr = []; // sp里有哪些ae商品id
    const extPidArr = []; // ext里有哪些ae商品id
    const extSp = [];

    // sp里有哪些ae商品id 现在订单数据有哪些ae商品
    console.log('lineItem.sp', lineItem.sp)
    lineItem.sp.forEach(sp => {
      if (sp.platform_product_id) {
        spAliPidArr.push(`${sp.platform_product_id}+_+${sp.platform_sku}`);
      }
    });

    // 这个订单ae供应商有哪些ae商品 目的是对比有没有更换mapping
    extList.forEach(item => {
      if (item.ali_id) {
        extPidArr.push(`${item.ali_id}+_+${item.sku}`);
      }
    });

    // 如果在shopify已经fulfilled那就把订单状态改成5
    /*
      如果仅有cancel reason和cancel at并且没有refunds 则为cancel单 不改状态
      目前在shopify直接操作并不能复现这种情况 需要用shopify api直接cancel才可以
    */
    if (
      shopfiyLineItem.fulfillment_status === 'fulfilled' &&
      !(detail.cancel_reason && detail.cancelled_at)
    ) {
      // lineitem返回2064 但shopifyLineitem fulfillment_status是fulfilled 但还有refunds 会把canceled单也变成fulfill单 所以剔除canceld状态
      // != 8192 是因为订单在shopify手动fulfilled 再return, 再在dsers回滚 同步到shopify是不能回滚成功的 要把订单放到canceled里 返回的lineitem状态是8192
      if (!detail.refunds && lineItem.status !== 8192) {
        // lineitem返回2064 但shopifyLineitem fulfillment_status是fulfilled 但还有refunds 会把canceled单也变成fulfill单
        lineItem.sp.forEach(sp => {
          sp.status = 5;
        });
        lineItem.status = 2048;
      }
    }

    const agencyLineItemIsAwaitingFulfill =
      lineItem?.status < 2048 &&
      lineItem?.sp?.find(i => i.status === 5 && i?.logistics_infos?.length) &&
      lineItem?.platform_type >= 5;

    lineItem.sp.forEach((sp, si) => {
      // 显示供应商信息
      let  supplierResult  =  showSupplierMes(sp,address,ActionEditExt,extList,Ext,transactions)
        if(supplierResult){
             sp.store_info =  supplierResult
           }
      if (sp.freight_cost == 'undefined') {
        sp.freight_cost = '0';
      }

      // pending awaitingorder canceld failed-order 这几个状态才更新订单信息
      if (
        !replaceExtStatus.includes(sp.status) 
        || (sp.status === 6 && isShopifyCanceled === 'shopify') || 
        agencyLineItemIsAwaitingFulfill
      ) {

        if (supplyPlatformEquals(lineItem.platform_type, 5)) {
          // const agencyUseExt = extList.find(i => i.map_type === 'MapAgency');
          // if (agencyUseExt) {
          //   canUpdateAgency(agencyUseExt, sp, shopfiyLineItem, lineItem);
          // }
          return;
        }
        setSpCost(sp, transactions, lineItem); // 更新商品价格和物流价格
        return;
      }

      // if( extList.length == 0 ){  // 最外层Ext没有匹配的供应商,返回的是空数组 把订单设成other单
      //   sp.platform_product_id = '';
      //   sp.platform_sku = '';
      //   sp.freights = null;
      //   sp.sku_img = '';
      //   sp.cost = 0;
      // }

      let replaceIndex = null;

      extList.forEach((ext, ei) => {
        if (ext.ali_id == '' && ext.map_type !== 'MapAgency') {
          return;
        }
        if (sp.is_edit) {
          if (
            ext.ali_id === sp.platform_product_id &&
            ext.sku === sp.platform_sku
          ) {
            canUpdateCost(ext, sp);
          }
          return;
        }

        // 如果是base mapping
        if (ext.map_type == 'MapBas') {
          // 如果主副供应商没变 但商品id或sku变了 找到更新索引 就return
          if (
            sp.is_default == ext.is_default &&
            (ext.ali_id != sp.platform_product_id || ext.sku != sp.platform_sku || (!ext.sale_id && sp.sale_id))
          ) {
            replaceIndex = ei;
            return;
          }

          // 如果现在订单数据都是1条 但主副供应商变化就需要替换
          if (
            lineItem.sp.length == 1 &&
            extList.length == 1 &&
            sp.is_default != ext.is_default
          ) {
            replaceIndex = ei;
            return;
          }

          // 都没有变更新商品成本或物流 ae店家可能改价格或物流
          if (
            sp.is_default == ext.is_default &&
            ext.ali_id == sp.platform_product_id &&
            ext.sku == sp.platform_sku
          ) {
            canUpdateCost(ext, sp);
            return;
          }
        }

        // 如果是advanced mapping standard mapping
        if (ext.map_type == 'MapAdv' || ext.map_type == 'MapSta') {
          // 商品id或sku变了 找到更新索引 就return
          if (
            ext.ali_id != sp.platform_product_id ||
            ext.sku != sp.platform_sku
          ) {
            replaceIndex = ei;
            return;
          }

          // 都没有变更新商品成本或物流 ae店家可能改价格或物流
          if (
            ext.ali_id == sp.platform_product_id ||
            ext.sku == sp.platform_sku
          ) {
            canUpdateCost(ext, sp);
            return;
          }
        }

        // agency mapping
        if(ext.map_type == 'MapAgency') {
          if(ext.sale_id !== sp.sale_id) {
            replaceIndex = ei;
            return;
          } else {
            canUpdateAgency(ext, sp, shopfiyLineItem, lineItem);
            return;
          }
        }

        // 如果是bogo mapping
        if (ext.map_type == 'MapBog') {
          // is_base是true就是主商品 false是赠品, 商品id或sku变了 找到更新索引 就return
          if (
            sp.is_base == ext.is_base &&
            (ext.ali_id != sp.platform_product_id || ext.sku != sp.platform_sku)
          ) {
            replaceIndex = ei;
            return;
          }

          // 都没有变更新商品成本或物流 ae店家可能改价格或物流
          if (
            sp.is_base == ext.is_base &&
            ext.ali_id == sp.platform_product_id &&
            ext.sku == sp.platform_sku
          ) {
            canUpdateCost(ext, sp);
            return;
          }

          // 主商品和赠品换位置
          if (
            sp.is_base !== ext.is_base &&
            ext.ali_id === sp.platform_product_id &&
            ext.sku === sp.platform_sku
          ) {
            canUpdateCost(ext, sp);
            return;
          }
        }

        // 如果是bundle mapping
        if (ext.map_type == 'MapBun') {
          // 都没有变更新商品成本或物流 ae店家可能改价格或物流
          if (
            ext.ali_id == sp.platform_product_id &&
            ext.sku == sp.platform_sku
          ) {
            canUpdateCost(ext, sp);
          }
        }

        // 如何增加了供应商就需要新增一个订单
        if (
          ((ext.map_type == 'MapBas' && hasBasMap(lineItem, extSp, ext)) ||
            ext.map_type == 'MapBun' ||
            ext.map_type == 'MapBog') &&
          !spAliPidArr.includes(`${ext.ali_id}+_+${ext.sku}`)
        ) {
          const newSp = {
            platform_order_id: '',
            platform_tracking_number: '',
            msg: '',
            err_code: '',
            err_msg: '',
            logistics_infos: null,
            status: sp.status,
            err: extErr
          };
          setSp(
            newSp,
            ext,
            lineItem,
            shopfiyLineItem,
            detail,
            tabStatus,
            fulfillments,
            si,
            transactions
          );
          extSp.push(newSp);
        }
      });

      // 如果需要替换 bundle没有替换只有新增或删除因为没有对应关系
      if (
        replaceIndex !== null &&
        ['MapBas', 'MapAdv', 'MapBog', 'MapSta'].includes(extList[replaceIndex].map_type)
      ) {
        replaceSp(sp, extList[replaceIndex], shopfiyLineItem, detail);
      }

      // agency
      if(replaceIndex !== null && extList[replaceIndex].map_type === 'MapAgency') {
        console.log('replace Agency 2');
        replaceAgencySp(sp, extList[replaceIndex], shopfiyLineItem, detail, agencyList);
      }

    });

    // const spAliPidArr = []; // sp里有哪些ae商品id
    // const extPidArr = [];   // ext里有哪些ae商品id
    // const extSp = [];

    // lineItem.sp.forEach( sp => {
    //   sp.platform_product_id && spAliPidArr.push( `${sp.platform_product_id}+_+${sp.platform_sku}` );
    // });

    // extList.forEach( item => {
    //   item.ali_id && extPidArr.push( `${item.ali_id}+_+${item.sku}` );
    // });

    // extList.forEach( ext => {
    //   if( !spAliPidArr.includes( `${ext.ali_id}+_+${ext.sku}` ) ){
    //     const newSp = {
    //       platform_order_id: '',
    //       platform_tracking_number: '',
    //       msg: '',
    //       err_code: '',
    //       err_msg: '',
    //       logistics_infos: null
    //     };
    //     setSp( newSp, ext, lineItem, shopfiyLineItem, detail, tabStatus, fulfillments );
    //     extSp.push( newSp );
    //   }
    // });

    const onlyOneSp = lineItem.sp[0];
    console.log(JSON.parse(JSON.stringify(lineItem.sp)), '看看有几个');
    const onlyOneSpStatus = getOnlyOneSpStatus(lineItem.sp, tabStatus);

    lineItem.sp = lineItem.sp.filter(sp => {
      if ([3, 4, 5].includes(sp.status)) {
        return true;
      }
      // 如果没有更换供应商 或这手动换绑过 或只为唯一一个other单 就保留
      return (
        extPidArr.includes(`${sp.platform_product_id}+_+${sp.platform_sku}`) ||
        sp.is_edit ||
        getHasOnlyOther(lineItem, extPidArr)
      );
    });
    // console.log(JSON.parse(JSON.stringify(lineItem.sp)), '-----------------child sp');
    const lessSp = [];
    if (extSp.length > 0 && !agencyLineItemIsAwaitingFulfill) {
      extSp.forEach(sp => {
        let flag = false;
        lineItem.sp.forEach(spItem => {
          if (lineItem.supplier_map_type == 3) {
            if (spItem.is_base == sp.is_base && spItem.is_edit) {
              // 举例：bogo子子订单 一个在order页edit(换绑),再在mapping处把换绑的那个子子订单换个新的mapping,这时会多出一个子子订单,要把它排除
              flag = true;
            }
            // 与现有相同
            if (
              spItem.is_base == sp.is_base &&
             (( spItem.platform_product_id == sp.platform_product_id &&
              spItem.platform_sku == sp.platform_sku))
            ) {
              flag = true;
            }
          }

          // 如果是base mapping 手动换绑过 也要把新增的排除
          if (lineItem.supplier_map_type == 1) {
            if (spItem.is_default == sp.is_default && spItem.is_edit) {
              flag = true;
            }
          }
        });

        if (flag) {
          // 排除
          return;
        }

        const s = JSON.stringify(sp);
        if (!lessSp.includes(s)) {
          lessSp.push(s);
        }
      });
    }

    if (lessSp.length > 0) {
      lessSp.forEach(sp => {
        const p = JSON.parse(sp);
        lineItem.sp.push(p);
      });
    }

    // 如果筛选后没有订单了 使用上面保存的哪个
    if (lineItem.sp.length === 0) {
      // 修改为other单 前提是不能是agency mapping
      if(!supplyPlatformEquals(lineItem.platform_type, 5)) {
        onlyOneSp.freights = [];
        onlyOneSp.cost = '0';
        onlyOneSp.variant_title = shopfiyLineItem.variant_title;
        onlyOneSp.platform_sku = '';
        onlyOneSp.platform_product_id = '';
        onlyOneSp.sale_id = '';
        onlyOneSp.store_info = {store_name:'',store_url:''};
        onlyOneSp.title = shopfiyLineItem.title || '';
        onlyOneSp.sku_img = '';
        onlyOneSp.status = onlyOneSpStatus || onlyOneSp.status;
        onlyOneSp.err = extErr;
      }
      lineItem.sp.push(onlyOneSp);
    }

    const spSkuArr = [];
    lineItem.sp = lineItem.sp.filter(sp => {
      // 一个lineitem 里的sp 不可能有相同sku的 所以以此依据去重
      const skuKey = `${sp.platform_product_id}+_+${sp.platform_sku}`;
      const flag = spSkuArr.includes(skuKey);
      let flag2 = true;
      if (!flag) {
        spSkuArr.push(skuKey);
      } else {
        flag2 = false;
      }

      flag2 && setSpCost(sp, transactions, lineItem);

      return flag2;
    });
    
    // 副供应商下单成功 然后在ae canceled， 这时 主供应商状态是2 副供应商是6，  然后用户把副供应商mapping删除  我就把副供应商sp 删除了， 剩下主供应商状态是2 所以是空白单
    // 目前只针对这个问题
    if (
      lineItem.sp.length === 1 &&
      [6, 7].includes(tabStatus) &&
      [6, 7].includes(shouldBeStatus)
    ) {
      lineItem.sp[0].status = shouldBeStatus;
      lineItem.sp[0].platform_order_id = shouldBePlatformOrderId;
    }

    // bogo mapping规定设置了主商品才成设置赠品 只有一个订单只能是主商品
    if (lineItem.sp.length === 1) {
      lineItem.sp[0].is_base = true;
    }

    lineItem.sp.forEach(sp => {
      // 如果是pending fulfillments 把订单数量变成0
      if (isPendingFulfillments && sp.status === 2) {
        sp.quantity = 0;
      }
    });

    //如果是other单
    // lineItem.sp.forEach(spItemChild => {
    //   if(!spItemChild.platform_sku && !spItemChild.platform_product_id && lineItem.sp.length == 1){
    //     lineItem.sp.length = 0

    //     let status = getOtherStatus(
    //       lineItem,
    //       shopfiyLineItem,
    //       detail,
    //       tabStatus,
    //       fulfillments
    //     )

    //     if(tabStatus == 6){
    //       status = 6
    //     }

    //     if(tabStatus == 7){
    //       status = 7
    //     }

    //     const obj = {
    //       platform_order_id: spItemChild.platform_order_id,
    //       platform_tracking_number: '',
    //       msg: '',
    //       err_code: '',
    //       err_msg: '',
    //       platform_product_id: '',
    //       platform_sku: '',
    //       logistics_company: '',
    //       logistics_infos: null,
    //       freights: undefined,
    //       quantity: getQty(
    //         shopfiyLineItem,
    //         {},
    //         detail,
    //         tabStatus,
    //         undefined,
    //         transactions,
    //         fulfillments
    //       ),
    //       cost: '0',
    //       freight_cost: '0',
    //       sale_id: '',
    //       is_default: true,
    //       is_base: true,
    //       status: status,
    //       title: shopfiyLineItem.title,
    //       sku_img: '',
    //       variant_title: shopfiyLineItem.variant_title
    //     };

    //     if(tabStatus !== 7){
    //       lineItem.sp.push(obj)
    //     }
    //   }
    // })
    // console.log(JSON.parse(JSON.stringify(lineItem.sp)), '-----------------child sp');
    return lineItem.sp;
  }

  const arr = [];

  // 订单没有保存过 根据ae供应商生成订单数据
  extList.forEach((item, ei) => {
    const sp = {
      platform_order_id: '',
      platform_tracking_number: '',
      msg: '',
      err_code: '',
      err_msg: '',
      logistics_infos: null,
    };

    if(item.map_type !== 'MapAgency') {
      setSp(
        sp,
        item,
        lineItem,
        shopfiyLineItem,
        detail,
        tabStatus,
        fulfillments,
        ei,
        transactions,
        dataItem
      );
    } else {
      setAgencySp(
        sp,
        item,
        lineItem,
        shopfiyLineItem,
        detail,
        tabStatus,
        fulfillments,
        ei,
        transactions,
        dataItem,
        agencyList
      )
    }
    arr.push(sp);
  });

  // 如果没有ae供应商 就生成一个other单
  if (arr.length == 0) {
    const obj = {
      platform_order_id: '',
      platform_tracking_number: '',
      msg: '',
      err_code: '',
      err_msg: '',
      platform_product_id: '',
      platform_sku: '',
      logistics_company: '',
      already_set_freights:'',
      logistics_infos: null,
      freights: undefined,
      quantity: getQty(
        shopfiyLineItem,
        {},
        detail,
        tabStatus,
        undefined,
        transactions,
        fulfillments
      ),
      cost: '0',
      freight_cost: '0',
      sale_id: '',
      is_default: true,
      is_base: true,
      status: getOtherStatus(
        lineItem,
        shopfiyLineItem,
        detail,
        tabStatus,
        fulfillments
      ),
      title: shopfiyLineItem.title,
      sku_img: '',
      variant_title: shopfiyLineItem.variant_title
    };

    // 没有sp 也没有mapping数据（订单shopify商品被删除） 生成 agency版本的other单
    if(supplyPlatformEquals(lineItem.platform_type, 5)) {
      obj.logistics_company = 'Other';
      obj.freights = [{
          "company": "Seller's shipping method",
          "freightAmount": {
              "currency": "USD",
              "formatedAmount": "0"
          },
          "serviceName": "Other",
          "standardFreightAmount": {
              "currency": "USD",
              "formatedAmount": "0"
          },
          "time": "",
          "tracking": true
      }];
      obj.sale_id = lineItem.agency_id || '';
    }

    arr.push(obj);
  }

  arr.forEach(sp => {
    // 如果是pending fulfillments 把订单数量变成0
    if (isPendingFulfillments && sp.status === 2) {
      sp.quantity = 0;
    }
  });

  return arr;
}

function getOtherStatus(
  lineItem,
  shopfiyLineItem,
  detail,
  tabStatus,
  extFulfillments = []
) {
  const {
    refunds,
    fulfillments,
    financial_status,
    cancel_reason,
    cancelled_at
  } = detail;

  let n = 2;

  if (lineItem.status === 1) {
    n = 1;
  }

  if (financial_status === 'pending') {
    n = 1;
    if (lineItem.status === 2) {
      // pending 手动fulfilled在rollback 下其他的订单 会把other但sp删除导致空白单
      n = 2;
    }
  }
  if (financial_status === 'paid') {
    n = 2;
  }
  if (extFulfillments.includes(lineItem.lineitem_id)) {
    n = 5;
  }

  if (shopfiyLineItem.fulfillment_status === 'fulfilled') {
    n = 5;
  }

  /**
   *  没有可以证明是canceled的订单 后台根据没有fulfillable_quantity返回到canceled 针对这种情况处理订单状态
   */
  if (
    lineItem.status === 16 &&
    shopfiyLineItem.fulfillable_quantity === undefined
  ) {
    n = 6;
  }

  // 在AE上cancel，接着在 canceled tab 下 order again, 造成没有 sp, 此时也展示在 cancel tab
  if (lineItem.status === 16 && !lineItem.sp) {
    n = 6;
  }

  if (tabStatus == 5 && fulfillments) {
    fulfillments.forEach(item => {
      item.status == 'success' &&
        item.line_items.forEach(line => {
          if (line.id == shopfiyLineItem.id) {
            n = 5;
          }
        });
    });
  }

  if (tabStatus == 6 && refunds) {
    refunds.forEach(re => {
      if (!re.refund_line_items) {
        return;
      }
      re.refund_line_items.forEach(item => {
        if (item.line_item_id == shopfiyLineItem.id) {
          n = 6;
        }
      });
    });
  }

  if (tabStatus == 6 && fulfillments) {
    fulfillments.forEach((item, i) => {
      if (i === 0 && item.status === 'pending') {
        item.line_items.forEach(li => {
          if (li.id === shopfiyLineItem.id) {
            n = 6;
          }
        });
      }
    });
  }
  // 8192展示在fulfill中
  if (
    hasCanceled(financial_status, cancel_reason, cancelled_at) &&
    lineItem.status !== 8192
  ) {
    // 只要shopify有一个子订单canceled(不是refunds) 整个订单就是canceled
    n = 6;
  }

  if (tabStatus == 7 && lineItem.status == 32) {
    n = 7;
  }

  if (
    lineItem.status === 2048 &&
    shopfiyLineItem.fulfillment_status === 'fulfilled'
  ) {
    n = 5;
  }

  return n;
}

function getOnlyOneSpStatus(sps, tabStatus) {
  let v = 0;
  sps.forEach(sp => {
    if (sp.status == tabStatus) {
      v = tabStatus;
    }
  });
  return v;
}

// 获取主 sp
function getUseSp(sp, lineItem, extList, map_type) {
  let obj = {};
  sp.forEach(item => {
    if (map_type == 1 && item.is_default) {
      obj = item;
    }

    if (map_type == 3 && item.is_base) {
      obj = item;
    }

    if (map_type == 2 || map_type == 5) {
      obj = item;
    }
  });
  if(map_type == 1 && sp.every((item=>item.status == 7)) && sp.length == 2){
    let findObj = sp.find(item=> !item.is_default)
    if(findObj){
      obj  = findObj
    }
   }
  return obj;
}

// 获取订单状态
function getStatus(sp, shopfiyLineItem, lineItem, map_type) {
  let status = 0;
  if (shopfiyLineItem.fulfillment_status == 'fulfilled') {
    status = 2048;
  } else {
    status = lineItem.status;
  }
  if (lineItem.status === 8192) {
    status = 8192;
  }
  return status;
}

// 获取bundle成本
function getBundleCost(sp) {
  let n = 0;
  sp.forEach(item => {
    n += item.cost * item.quantity;
  });
  return n;
}

// 获取是否是 shopify canceled的
function getCancelBy(shopfiyLineItem, detail, extLineItem = {}) {
  const { refunds, financial_status, cancel_reason, cancelled_at } = detail;
  let s = '';

  if (hasCanceled(financial_status, cancel_reason, cancelled_at)) {
    return 'shopify';
  }

  // 订单在shopify手动fulfilled 再return, 再在dsers回滚 同步到shopify是不能回滚成功的 要把订单放到canceled里 返回的lineitem状态是8192
  if (extLineItem.status === 8192) {
    s = 'shopify';
  }

  if (!refunds) {
    return s;
  }

  refunds.forEach(re => {
    if (
      !re.refund_line_items &&
      ['voided', 'refunded'].includes(financial_status)
    ) {
      s = 'shopify';
    }
    if (!re.refund_line_items) {
      return;
    }
    re.refund_line_items.forEach(line => {
      if (line.line_item_id === shopfiyLineItem.id) {
        s = 'shopify';
      }
    });
  });

  return s;
}

// 获取是否是 AE cancel
function getAECanceled(
  sp = [],
  transactions,
  newSp,
  isShopifyCanceled,
  shopfiyLineItem
) {
  const hasFulfillableQuantity = shopfiyLineItem.fulfillable_quantity > 0;
  let arr = [];

  newSp.forEach(item => {
    const { platform_order_id } = item;
    if (!transactions || !platform_order_id) {
      if (
        isShopifyCanceled === '' &&
        hasFulfillableQuantity &&
        item.status === 6
      ) {
        arr.push(1);
      } else {
        arr.push(0);
      }
      return;
    }

    let flag = false;

    transactions.forEach(tr => {
      if(!tr.hasOwnProperty('order_info')){
        return
      }

      if(!tr.order_info[platform_order_id]){
        tr.order_info[platform_order_id] = {
          order_status: '',
          logistics_status: ''
        }
      }
      const {
        order_info: {
          [platform_order_id]: { order_status = '', logistics_status } = {}
        } = {}
      } = tr;

      if (
        (order_status === 'FINISH' && logistics_status === 'NO_LOGISTICS') ||
        order_status === 'IN_CANCEL'
      ) {
        flag = true;
      }
    });

    if (flag && hasFulfillableQuantity) {
      arr.push(1);
    } else if (
      isShopifyCanceled === '' &&
      hasFulfillableQuantity &&
      item.status === 6
    ) {
      arr.push(1);
    } else {
      arr.push(0);
    }
  });

  if (arr.length < newSp.length) {
    arr = new Array(newSp.length).fill(arr[0]);
  }

  return arr;
}

function getAgencyCanceled(lineItem, sp, agencyOrderList = []) {
  if(supplyPlatformEquals(lineItem.platform_type, 5) && sp.platform_order_id) {
    return !!agencyOrderList.find(i => i.Status === 5 && i.Id === sp.platform_order_id);
  }
}

// 失败单状态 是否展示 保存 取消 按钮 (地址错误才展示)
function getShowSave(ext, tabStatus) {
  if (tabStatus != 7) {
    return false;
  }

  let flag = false;
  const failedCodeArr = [6, 10]; // code是6||10 就是地址错误
  const { err: { code, msg = '' } = {} } = ext; // code订单错误类型 msg里面是address具体那个字段错了

  if (code === 0) {
    return flag;
  }

  if (failedCodeArr.includes(code)) {
    flag = true;
  }

  // msg格式是 "[8, 7]" 这种 大于2说明有值
  if (msg.length > 2) {
    flag = true;
  }

  if (code === 11) {
    flag = true;
  }

  return flag;
}

function distinct(saleIds, newSaleIds) {
  const aa = Array.from(new Set([...saleIds]));
  const bb = Array.from(new Set([...newSaleIds]));

  return !(bb.length > aa.length);
}

function lineitemsHasMore(lineitems, detail) {
  const { line_items = [] } = detail;

  line_items.forEach(item => {
    const li = lineitems.find(line => line.lineitem_id == item.id);

    if (li == undefined) {
      lineitems.push({
        lineitem_id: item.id,
        platform_type: 1,
        product_id: item.product_id,
        status: 2,
        supplier_map_type: 1,
        variant_id: item.variant_id,
        newLineItem: true
      });
    }
  });
}

// 当前子订单是不是shopify pending fulfillment 状态
function getIsPendingFulfillments(shopfiyLineItem, detail) {
  const { fulfillments } = detail;
  let flag = false;

  if (!fulfillments) {
    return flag;
  }

  flag = fulfillments.some(ful => {
    const { status, line_items = [] } = ful;
    if (status !== 'pending') {
      return false;
    }
    return line_items.some(item => item.id === shopfiyLineItem.id);
  });
  return flag;
}

// 获取等待AE供应商确认取消的订单
function getAwaitingCancellationOnAE(sp) {
  const obj = {};
  if (!sp) {
    return obj;
  }
  sp.forEach(item => {
    if (item.status === 10) {
      obj[`${item.platform_product_id}--${item.platform_sku}`] = true;
    }
  });
  return obj;
}

// mock模拟后台数据

const MockStatus = [
  {
    id: 'c1e2soth3uj000e0mud0',
    pStatus: 1
  },
  {
    id: 'c1dgdj5h3uj000e04jqg',
    pStatus: 2
  },
  {
    id: 'c18m8559rrfg00ctrltg',
    pStatus: 2
  }
];

// 处理订单数据
export const getOrderShowData = (
  data,
  tabStatus, // 订单在那个tab下 pending 还是 awating order
  types, // 用户付费等级
  userInfo,
  timezoneRegion, // setting->report页设置的时区
  agencyList = []
) => {
  const {
    DsersExt = {}, // 订单包含的商品对应的aliexpress商品数据 mappping数据（dsers自营商品数据）
    Ext = [], // 订单包含的商品对应的aliexpress商品数据 mappping数据
    ActionEditExt ={},//编辑过的商品中含有的供应商信息
    Orders: { orders = [] }, // 订单列表
    Skus = {}, // 从aliexpress抓取的商品信息
    FulfillJobStatusList = [],
    SpTimeLines,
    AgencyOrders,
  } = data;
  const list = [];

  orders.forEach((item, index) => {
    const {
      detail, // shopify订单详情
      detail: { line_items = [] }, // line_items里的是shopify具体订单数据 有几个等等
      ext = {}, // 用来去AE下单的数据
      ext: { lineitems = [], address = {}, fulfillments, transactions } // lineitems(对应detail里的line_items) address(对应detail里billing_address和shipping_address) fulfillments手动弄成已完成的lineitem_id
    } = item;

    lineitemsHasMore(lineitems, detail); // 在shopify 可以编辑订单 增加一个子订单 ext.lineitems 里的不会变 对比一下 少了补上

    let orderDate = moment(detail.created_at).format('HH:mm YYYY-MM-DD');
    // 订单时间 如果设置了时区 把时间挪到那个时区
    if (timezoneRegion) {
      orderDate = moment(detail.created_at)
        .tz(timezoneRegion)
        .format('HH:mm YYYY-MM-DD');
    }

    ext.address = ext.address || { ...emptyAddress };
    ext.flags = ext.flag || 0;
    const listData = {
      orderNumber:
        detail?.name?.indexOf('#') == 0
          ? detail.name.substring(1)
          : detail.name,
      date: orderDate,
      total: Number(detail.total_price), // 订单总收入
      currency: detail.currency || 'USD', // 货币
      note: ext.note, // 订单备注
      childrens: [], // 一条订单包含几个子订单(相当于买东西一次付款里面有多少商品)
      id: item.id, // 订单id 在dsers的订单id
      storeId: item.store_id, // shopify 店铺id
      ext, // 用来去AE下单的数据
      showSave: getShowSave(ext, tabStatus), // 是否显示保存按钮
      disabled: false, // 是都禁用
      canOrderDisabled: false, // 下否可下单
      checked: true,
      Skus, // 从aliexpress抓取的商品信息
      detail, // shopify订单详情
      shopifyUrl: getShopifyUrl(userInfo, detail, item.store_id), // 获取订单在shopify上的详情地址
      isShopifyDelete: item.is_delete, // 订单是否在shopify删除
      AgencyOrders, // agency订单详情
    };
    // console.log(FulfillJobStatusList, item);
    // 处理fulfill逻辑
    FulfillJobStatusList?.forEach(itemKey => {
      if (itemKey.OrderId === item.id) {
        listData.fulfillStatus = itemKey.Status;
      }
    });

    // 一条订单中是否有同商品 不同sku 导致ae order id不同的商品
    const aeSplitedProductHelper = {};
    const aeSplitedProductsMap = {};

    let saleIds = []; // 可以下单的商品有多少卖家id
    let newSaleIds = []; // 替换成dsers供应商后有多少卖家id
    let canReplace = true; // 是否可以用dsers供应商替换ae的

    line_items.forEach((lineItem, i) => {
      const ids = getExtListSaleIds(
        lineitems[i],
        Ext,
        address,
        lineItem,
        DsersExt
      );
      saleIds = [...saleIds, ...ids[0]];
      newSaleIds = [...newSaleIds, ...ids[1]];
    });

    canReplace = distinct(saleIds, newSaleIds); // 新的卖家数量大于ae卖家数量就不能替换

    line_items.forEach((lineItem, i) => {
      const originExtLineItem = cloneDeep(lineitems[i]);
      const isPendingFulfillments = getIsPendingFulfillments(lineItem, detail); // 获取当前子订单是不是shopify pending fulfillment 状态
      const extList = getExtList(
        lineitems[i],
        Ext,
        address,
        lineItem,
        DsersExt,
        canReplace,
        types
      ); // 获取子订单包含的商品对应的aliexpress商品数据(这个shopify商品对应ae的那个商品,就是商品mapping数据)
      
      const childCurrentMappingSupplyType = extList?.[0]?.platform_type || 1;
      lineitems[i].childCurrentMappingSupplyType = childCurrentMappingSupplyType;
      let childCurrentAgencyId = undefined;
      if(childCurrentMappingSupplyType >= 5) {
        childCurrentAgencyId = extList?.[0]?.sale_id || '';
        lineitems[i].childCurrentAgencyId = childCurrentAgencyId;
      }
      const awaitingCancellationOnAE = getAwaitingCancellationOnAE(
        lineitems[i].sp
      ); // 获取等待AE供应商确认取消的订单

      const useExt = getUseExt(lineitems[i], extList); // 主供应商

      extList.forEach((ext, ei) => {
        if (
          ext.prod_id  ===  lineitems[i].product_id && ext.var_id  ===  lineitems[i].variant_id
        ) {
          lineItem.store_info  = ext.store_info;
          // 如果是新添加的item 需要修正platform_type
          if(lineitems[i].newLineItem) {
            lineitems[i].platform_type = ext.platform_type || 1;
            delete lineitems[i].newLineItem;
          }
        }
      });
      
      const isShopifyCanceled = getCancelBy(lineItem, detail, lineitems[i]);

      // 获取 sp里的数据就是从供应商里取的
      let sp = getChildSp(
        lineitems[i],
        ActionEditExt,
        Ext,
        extList,
        lineItem,
        address,
        detail,
        tabStatus,
        fulfillments,
        transactions,
        isPendingFulfillments,
        ext.err,
        undefined,
        agencyList,
        isShopifyCanceled
      );

      
      //检测如果副供应商在前边就进行反转
      if(lineitems[i].supplier_map_type !== 4){
        sp = !sp[0]?.is_default ? sp.reverse() : sp;
      }
      // 先注释掉，可能造成其他情况的pending空白单，年久失修已经忘记解什么加的这代码了
      // if(tabStatus === 1){
      //   var arrSp = []

      //   Object.keys(statusMapKey).forEach(mapKey => {
      //     lineitems.forEach(lineit => {
      //       if(lineit.status & mapKey){
      //         arrSp.push(statusMapKey[mapKey]) 
      //       }
      //     })
      //   })
  
      //   let filterData = []
  
      //   arrSp.map((el,index) => {
      //     if(!sp.find(item => item.status == el)){
      //       filterData.push(el)
      //     }
      //   })
  
      //   filterData.map(dataItem => {
      //     let spK = getChildSp(
      //       lineitems[i],
      //       ActionEditExt,
      //       Ext,
      //       extList,
      //       lineItem,
      //       address,
      //       detail,
      //       tabStatus,
      //       fulfillments,
      //       transactions,
      //       isPendingFulfillments,
      //       ext.err,
      //       dataItem
      //     );
      //     sp = [...sp, ...spK]
  
      //   })
      // }
      //拼接sp
      sp.forEach(item => {
        let st = SpTimeLines[item.platform_order_id]?.LastSyncTime * 1000;
        let sp = SpTimeLines[item.platform_order_id]?.NextSyncTime * 1000;

        if (st && sp) {
          item.time = {
            LastSyncTime: timezoneRegion
              ? moment(st)
                  .tz(timezoneRegion)
                  .format('HH:mm YYYY-MM-DD')
              : moment(st).format('HH:mm YYYY-MM-DD'),
            NextSyncTime: timezoneRegion
              ? moment(sp)
                  .tz(timezoneRegion)
                  .format('HH:mm YYYY-MM-DD')
              : moment(sp).format('HH:mm YYYY-MM-DD')
          };
        }
      });
      const agencyLineItemIsAwaitingFulfill =
        lineitems[i]?.status < 2048 &&
        lineitems[i]?.sp?.find(i => i.status === 5 && i?.logistics_infos?.length) &&
        lineitems[i]?.platform_type >= 5;
      let map_type =
        (extList[0] && maptype[extList[0].map_type]) ||
        lineitems[i].supplier_map_type ||
        1; // mapping类型
      // 等待支付或发货或已完成的订单 不应根据mapping类型变化
      if ([3, 4, 5].includes(tabStatus) || agencyLineItemIsAwaitingFulfill) {
        map_type = lineitems[i].supplier_map_type || 1;
      }

      const useSp = getUseSp(sp, lineitems[i], extList, map_type); // 获取主供应商
      // const isShopifyCanceled = getCancelBy(lineItem, detail, lineitems[i]); // 订单是不是shopify取消
      // lineitem 的fulfillment状态 用于区分
      const shopifyFulfillStatus = getLineitemFulfillmentStatus(
        detail,
        fulfillments,
        line_items[i]
      );
      
     const obj = {
        image:
          (sp[0] && sp[0].sku_img) ||
          (extList[0] && extList[0].var_img) ||
          defaultImg,
        title: lineItem.title || '',
       store_info: lineItem.store_info,
       sp_message:lineitems[i]?.sp_message || '',
        variant: useSp.variant_title || lineItem.variant_title,
        qty:
          useSp.quantity || lineItem.fulfillable_quantity || lineItem.quantity,
        cost: getBundleCost(sp),

        orderNo: useSp.platform_order_id ? [useSp.platform_order_id] : [],
        trackNo: useSp.platform_tracking_number
          ? [useSp.platform_tracking_number]
          : [],
        freightDefault: useSp.logistics_company,

        useSp,
        sp,
        extList, // ae供应商列表
        is_black_list: muilteBlackListStatus(lineItem, lineitems), // 是不是加黑商品
        platform_type: lineitems[i].platform_type,
        agency_auth_status: lineitems[i].place_agency_auth_status || 2,
        count: lineItem.fulfillable_quantity
          ? (lineItem.fulfillable_quantity * lineItem.price).toFixed(2)
          : 0,
        showBind: [false], // 是否展示手动换绑

        supplierMapType: map_type, // mapping类型  base 1  advance 2  bogo 3  bundle 4 standard 5

        status: getStatus(sp, lineItem, lineitems[i], map_type),
        platformType:
          extList[0] && extList[0].ali_id ? 2 : lineitems[i].platform_type || 1,
        platformProductId: useExt.ali_id,
        variantId: lineItem.variant_id,
        shopifyProductId: lineItem.product_id,
        id: lineItem.id,
        isBase: useExt.is_base,
        isDefault: useExt.is_default,

        markAsFulfilledChecked: true,
        markAsFulfilledFeedback: '',

        canceledBy: isShopifyCanceled,
        AECanceled: getAECanceled(
          originExtLineItem.sp,
          transactions,
          sp,
          isShopifyCanceled,
          lineItem
        ), // ae canceled后改变mapping全部替换掉之前的商品 sp都会更新 订单号就没了 无法判断为ae取消的 所以用后台返回的sp判断
        storeId: item.store_id,
        isPendingFulfillments,
        awaitingCancellationOnAE,
        shopifyFulfillStatus,
        // agencyCanceled: getAgencyCanceled(lineItem, sp[0] || {}, AgencyOrders),
        agencyCanceled: !!getAECanceled(
          originExtLineItem.sp,
          transactions,
          sp,
          isShopifyCanceled,
          lineItem
        )?.[0],
        childCurrentMappingSupplyType,
        childCurrentAgencyId
      };
      // console.log(supplyPlatformEquals(lineitems[i].platform_type, 5), sp?.[0]?.status === 3, sp?.[0]?.platform_order_id)
      if(supplyPlatformEquals(lineitems[i].platform_type, 5) && sp?.[0]?.status >= 3 && sp?.[0]?.platform_order_id) {
        const orderExsits = AgencyOrders.find(i => i.Id === sp?.[0]?.platform_order_id);
        // console.log(orderExsits);
        if (orderExsits) {
          const agencyOrderInfo = orderExsits?.Items?.find(
            agencyOrderItem =>
              `${agencyOrderItem.LineItemId}` ===
              `${lineitems[i].lineitem_id}`
          );
          // 上传的是空或者没有值就是 USD， 否则取上传的
          const lineItemAgencyPaymentCurrency =
          agencyOrderInfo?.Currency || 'USD';
          obj.lineItemAgencyPaymentCurrency = lineItemAgencyPaymentCurrency;
          obj.manuallyUploadShippingCost = agencyOrderInfo?.IsImportPayment;
        } else {
          // 没上传过就是USD 没上传过支付接口不支持 所以注释掉
          obj.lineItemAgencyPaymentCurrency = 'USD';
          obj.manuallyUploadShippingCost = false;
        }
      } else {
        obj.lineItemAgencyPaymentCurrency = 'USD';
        obj.manuallyUploadShippingCost = false;
      }

      // 检查此订单中的相同ae商品是否被ae拆分为多个ae订单
      let checkSps = [];
      let bogoIndex = -1;
      switch (obj.supplierMapType) {
        case 1:
        case 2:
          checkSps.push(useSp);
          break;
        case 3:
          checkSps.push(useSp);
          bogoIndex = sp.findIndex(bogoSp => !bogoSp.is_base);
          if (bogoIndex > -1) {
            checkSps.push(sp[bogoIndex]);
          }
          break;
        case 4:
          checkSps = sp;
          break;
        default:
          break;
      }

      checkSps.forEach((checkSp = {}) => {
        // 子订单Ae order id
        const spAeOrderId = checkSp.platform_order_id;
        // 子订单Ae order id是否来自transaction
        // 如果不是则是用户手动设置的 则不再判定为ae拆分单
        const isOriginalOrderId =
          transactions?.findIndex(transaction => {
            // console.log(transaction?.order_info?.[spAeOrderId]?.order_status, checkSp, 'status')
            return (
              transaction?.order_info?.[spAeOrderId]?.order_status ===
              'PLACE_ORDER_SUCCESS'
            );
          }) > -1;
        // order id 来自transaction
        if (isOriginalOrderId) {
          const savedAeOrderId =
            aeSplitedProductHelper[checkSp.platform_product_id];
          if (savedAeOrderId && savedAeOrderId !== checkSp.platform_order_id) {
            aeSplitedProductsMap[checkSp.platform_product_id] = true;
          } else {
            aeSplitedProductHelper[checkSp.platform_product_id] =
              checkSp.platform_order_id;
          }
        }
      });

      listData.ext.lineitems[i].sp = sp;
      listData.ext.lineitems[i].supplier_map_type = map_type;
      if (listData.ext.updated_at) {
        listData.ext.updated_at = listData.ext.updated_at * 1;
      }
      
      listData.childrens.push(obj);
    });

    let arrSy = {};
    let cancelNone = [];
    let cancelFind = [];

    lineitems.forEach(lintItemG => {
      detail.fulfillments?.forEach(fulfillItem => {
        fulfillItem?.line_items?.forEach(lintIt => {
          if (
            lintItemG.lineitem_id == lintIt.id &&
            fulfillItem.status == 'success' &&
            fulfillItem?.tracking_numbers
          ) {
            if (arrSy.hasOwnProperty(lintIt.id)) {
              arrSy[lintIt.id] = [
                ...cancelNone,
                ...arrSy[lintIt.id],
                ...fulfillItem.tracking_numbers
              ];
            } else {
              cancelNone = fulfillItem.tracking_numbers;
              arrSy[lintIt.id] = fulfillItem.tracking_numbers;
            }
          }
        });
      });
    });

    lineitems.forEach(Y => {
      Y.K = [...new Set(arrSy[Y.lineitem_id])];
    });

    lineitems.forEach(Y => {
      if (Y.supplier_map_type == 3 || Y.supplier_map_type == 4) {
        Y.sp?.forEach(spKy => {
          if (spKy.platform_order_id) {
            Y.K = [];
          }
        });
      }
    });

    lineitems?.forEach((lineI, i) => {
      lineI.sp?.forEach((itemF, K) => {
        if (!itemF.platform_order_id) {
          itemF.TN = lineI.K;
        } else {
          itemF.TN = null;
        }
      });
    });

    // 被aliexpress拆分为多个ae订单的商品 用于展示提示
    listData.aeSplitedProductsMap = aeSplitedProductsMap;

    list.push(listData);
  });


  

  return list;
};

//计算加黑状态
const muilteBlackListStatus = (lineItem, lineitems) => {
  let status = null;
  lineitems?.map(lineItems => {
    if(lineItems?.lineitem_id == lineItem?.id  && lineItems?.is_black_list === 2){
      status =  lineItems?.is_black_list
    }
  })
  return status
}

export function checkLineItemBannerVisible(data, child) {
  const { Skus = {} } = data;
  const { extList = [] } = child;
  let result = false;

  child.sp.forEach(sp => {
    const { err: { code } = {} } = sp;

    if (sp.status !== 7) {
      return;
    }
    if ([15, 19].includes(code)) {
      result = true;
      return;
    }

    const prod = Skus[sp.platform_product_id];
    let aeSkuHasChange = true;

    if (prod) {
      prod.skuPriceList.some(item => {
        if (item.skuAttr === sp.platform_sku) {
          aeSkuHasChange = false;
          if (!item.skuVal.availQuantity && code === 13) {
            // 无库存
            result = true;
          }
          return true;
        }
        return false;
      });

      if (aeSkuHasChange) {
        // sku修改
        result = true;
      }
    }

    extList.some(item => {
      if (item.sku === sp.platform_sku && item.quantity === sp.quantity) {
        if (sp.quantity && item.limit !== 0) {
          // 限购
          result = true;
        }
        if (item.status !== 'onSelling') {
          // 下架
          result = true;
        }
        return true;
      }
      return false;
    });
  });

  return result;
}

/**
 * code: 15 blacklisted
 * code: 13 out of stock
 *
 * @param {*} data
 * @returns
 */
export const checkBannerVisibleByCode = data => {
  const {
    childrens = [],
    ext: { multiple_platform_error = {} },
    Skus = {}
  } = data;

  const code = multiple_platform_error?.agency?.code;
  let result = false;

  if ([15, 19].includes(code)) {
    result = true;
  }

  if (result) {
    return result;
  }

  childrens.forEach(child => {
    result = checkLineItemBannerVisible(data, child);
  });
  return result;
};

export const checkAeSkuChange = (data, sp) => {
  let aeSkuHasChange = true;

  // eslint-disable-next-line
  for (const item of data) {
    if (item.skuAttr === sp.platform_sku) {
      aeSkuHasChange = false;
      break;
    }
  }

  return aeSkuHasChange;
};

//智利号校验规则 rut
export const checkChileTaxNumberValid = value => {
  // reference: https://github.com/jlobos/rut.js
  // return /^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(value);
  return true;
};

export const checkPassPortNo = value => {
  // reference:P123456789012
  return /^P[0-9]{12}$/i.test(value);
};

export const checkAlienPassPortNo = value => {
  // reference:P1234567890123
  return /^[0-9a-zA-Z]{1,13}$/i.test(value);
};

export const sleep = timeout => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

export const supplyPlatformEquals = (dataSupplyType, logicSupplyType) => {
  if(logicSupplyType === 5) {
    return dataSupplyType >= 5;
  }
  return dataSupplyType === logicSupplyType;
}

export const filterUpdateExtLineItems = (logicSupplyType, ext, originalExt) => {
  console.log(logicSupplyType, ext, originalExt, '看看进来了啥');
  const newExt = {
    ...ext,
    lineitems: ext.lineitems.map(i => {
      const originalLineItem = originalExt.lineitems?.find(originalItem => originalItem.lineitem_id === i.lineitem_id);
      // 原始数据中没有这个line item 说明是sp edit 新增的直接提交修改过的即可
      if(!originalLineItem) return i;
      // 原始数据中存在这个line item 判断类型是否与当前tab一致 不一致时
      if(supplyPlatformEquals(originalLineItem.platform_type, logicSupplyType)) {
        console.log('类型对的，允许修改', i.lineitem_id);
        return i;
      } else {
        console.log('类型不对，不要动我', i.lineitem_id);
        return originalLineItem;
      }
    })
  }
  return newExt;
}

export const filterRollbackExtLineItems = (logicSupplyType, ext, originalExt, rollbackedItemsMap = {}) => {
  const newExt = {
    ...ext,
    lineitems: ext.lineitems.map(i => {
      const originalLineItem = originalExt.lineitems?.find(originalItem => originalItem.lineitem_id === i.lineitem_id);
      // 原始数据中没有这个line item 说明是sp edit 新增的直接提交修改过的即可
      if(!originalLineItem) return i;

      if(rollbackedItemsMap[originalLineItem.lineitem_id]) {
        return i;
      }

      // 原始数据中存在这个line item 判断类型是否与当前tab一致 不一致时
      if(supplyPlatformEquals(originalLineItem.platform_type, logicSupplyType)) {
        console.log('类型对的，允许修改', i.lineitem_id);
        return i;
      } else {
        console.log('类型不对，不要动我', i.lineitem_id);
        return originalLineItem;
      }
    })
  }
  return newExt;
}

export const getWholeOrderAgencyFreightCostParams = (orders, status) => {
  try {
    const params = orders
      .map(i => {
        const lineitems = [];
        i.childrens.forEach(child => {
          if (child.is_black_list == 2) {
            return;
          }
          if (!supplyPlatformEquals(child.platform_type, 5)) {
            return;
          }
          if (child?.sp?.length !== 1) return;
          if (child?.manuallyUploadShippingCost) {
            return;
          }

          const useSp = child.sp[0];

          if (status !== useSp?.status) {
            return;
          }

          if (![1, 2, 6, 7].includes(useSp?.status)) {
            return;
          }

          if(useSp?.status === 6 && !child.agencyCanceled) {
            return;
          }

          if(!useSp.platform_product_id) {
            return;
          }

          if(!useSp.platform_sku) {
            return;
          }

          if (!useSp.logistics_company) {
            return;
          }

          // if (useSp.logistics_company === 'Other') {
          //   return;
          // }

          if (!useSp.sale_id) {
            return;
          }

          // lineitems.push({
          //   product_id: child.shopifyProductId,
          //   variant_id: child.variantId,
          //   quantity: useSp?.quantity,
          //   shipping_id: parseInt(useSp.logistics_company),
          //   agency_id: parseInt(useSp?.sale_id),
          //   country: i.ext?.address?.country_code
          // });

          lineitems.push({
            product_id: useSp.platform_product_id,
            variant_id: useSp.platform_sku,
            quantity: useSp?.quantity,
            shipping_id: `${useSp.logistics_company}`,
            agency_id: parseInt(useSp?.sale_id),
            country: i.ext?.address?.country_code
          });
        });

        return { order_id: i.id, lineItems: lineitems, store_id: i.storeId };
      })
      .filter(i => {
        return !!i.lineItems.length;
      });
      
    console.log(params, '获取物流的参数');
    if(!params.length) {
      return null;
    }

    return params;
    

  } catch (error) {
    console.log(error)
  }
};

// [
//   {
//       "id": 1,
//       "company": "test001",
//       "shipping_cost": "15.10",
//       "delivery_time": "10",
//       "tracking": 1
//   }
// ]

export const transformFreightList = (newList) => {
  return newList.map(i => {
    return {
      company: i.company_name,
      freightAmount: {
        currency: 'USD',
        formatedAmount: i.amount,
        value: parseFloat(i.amount) || 0
      },
      serviceName: `${i.service_id}`,
      standardFreightAmount: {
        currency: 'USD',
        formatedAmount: i.amount,
        value: parseFloat(i.amount) || 0
      },
      time: `${i.min} - ${i.max}`,
      tracking: !!i.tracking
    };
  })
}